import { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { changeLanguage } from '../redux/languageSlice'
import { useDispatch } from 'react-redux'
import SignModal from './LogInLogOut/SignModal'
import { useValidToken } from '../hooks/useValidToken'
import { useLocation } from 'react-router-dom'
import SignOutModal from './LogInLogOut/SignOutModal'
import logo from '../images/boosthub/transparentSmallLogo.png'
import navbarMenu from '../images/navbarMenu.svg'
import useWidthScreenSize from '../hooks/useWidthScreenSize'
import { MOBILE_SCREEN_SIZE } from '../constants'
import '../styles/navbar.css'


const Navbar = () => {
  const location = useLocation().pathname
  const LOCATION_TO_SHOW_SIGN_OUT = "/personal-area"
  const [isOpenSignModal, setIsOpenSignModal] = useState(false)
  const [alreadyHaveAccount, setAlreadyHaveAccount] = useState(false)
  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState(false)
  const [showNavbarMenu, SetShowNavbarMenu] = useState(false)
  const dispatch = useDispatch()
  const changeLanguageHandler = (ev) => dispatch(changeLanguage(ev.target.value))
  const screenWidth = useWidthScreenSize()
  const { token, isValidToken } = useValidToken()

  const closeMobileNavbar = () => SetShowNavbarMenu(false)

  const handleSign = (isSignIn) => {
    setIsOpenSignModal(true)
    if (isSignIn) setAlreadyHaveAccount(true)
    else setAlreadyHaveAccount(false)
    if (screenWidth < MOBILE_SCREEN_SIZE) closeMobileNavbar()
  }

  const handleSignOut = () => {
    setIsSignOutModalOpen(true)
    if (screenWidth < MOBILE_SCREEN_SIZE) closeMobileNavbar()
  }


  if (screenWidth > MOBILE_SCREEN_SIZE) {
    return (
      <nav>
        {isOpenSignModal && <SignModal setIsOpenSignModal={setIsOpenSignModal} alreadyHaveAccount={alreadyHaveAccount} setAlreadyHaveAccount={setAlreadyHaveAccount} />}
        {isSignOutModalOpen && <SignOutModal setIsSignOutModalOpen={setIsSignOutModalOpen} />}
        <article className="navlink-container">
          <Link className='navlink-logo-container' to="/">
            <img src={logo} alt='Boost-hub logo' className='logo-navbar' />
            <span className='nav-web-name'>oostHub</span>
          </Link>
          <NavLink to="/courses" className="navlink"><FormattedMessage id="courses" /></NavLink>
          <NavLink to="/web3-mentory" className="navlink"><FormattedMessage id="mentoring" /></NavLink>
          <NavLink to="/web3-resources" className="navlink"><FormattedMessage id="more-resources" /></NavLink>
        </article>


        <article className="rigth-nav-container">
          {!token || !isValidToken ?
            <div className='sign-container'>
              <button onClick={() => handleSign(true)} className="navlink" ><FormattedMessage id="sign-in" /></button>
              <button onClick={() => handleSign(false)} className="sign-navlink" ><FormattedMessage id="sign-up" /></button>
            </div>
            :
            <div className='sign-container'>
              <NavLink to="/personal-area" className="navlink" ><FormattedMessage id="personal-area" /></NavLink>
              {location === LOCATION_TO_SHOW_SIGN_OUT && <button onClick={handleSignOut} className="navlink" ><FormattedMessage id="sign-out" /></button>}
            </div>}

          <select className="language-selector" onChange={changeLanguageHandler}>
            <option default disabled><FormattedMessage id="language" /></option>
            <option value="en"><FormattedMessage id="english" /></option>
            <option value="esp"><FormattedMessage id="spanish" /></option>
          </select>
        </article>
      </nav>
    )
  } else {
    return (
      <nav>
        {isOpenSignModal && <SignModal setIsOpenSignModal={setIsOpenSignModal} alreadyHaveAccount={alreadyHaveAccount} setAlreadyHaveAccount={setAlreadyHaveAccount} />}
        {isSignOutModalOpen && <SignOutModal setIsSignOutModalOpen={setIsSignOutModalOpen} />}
        <div className='mobile-navbar-main-line'>
          <img src={navbarMenu} alt='menu-logo' className='mobile-navbar-menu-logo' onClick={() => SetShowNavbarMenu(!showNavbarMenu)} />
          <Link className='navlink-logo-container' to="/">
            <img src={logo} alt='Boost-hub logo' className='logo-navbar' />
            <span className='nav-web-name'>oostHub</span>
          </Link>
          <select className="language-selector" onChange={changeLanguageHandler}>
            <option default disabled><FormattedMessage id="language" /></option>
            <option value="en"><FormattedMessage id="english" /></option>
            <option value="esp"><FormattedMessage id="spanish" /></option>
          </select>
        </div>
        {showNavbarMenu && <div className='mobile-navbar-options-line'>
          {!token || !isValidToken ?
            <>
              <button onClick={() => handleSign(false)} className="mobile-sign-up" ><FormattedMessage id="sign-up" /></button>
              <button onClick={() => handleSign(true)} className="mobile-sign-in" ><FormattedMessage id="sign-in" /></button>
            </>
            :
            <>
              <NavLink to="/personal-area" className="navlink" onClick={closeMobileNavbar} ><FormattedMessage id="personal-area" /></NavLink>
              {location === LOCATION_TO_SHOW_SIGN_OUT && <button onClick={handleSignOut} className="navlink" ><FormattedMessage id="sign-out" /></button>}
            </>}

          <NavLink to="/courses" className="navlink" onClick={closeMobileNavbar}><FormattedMessage id="courses" /></NavLink>
          <NavLink to="/web3-mentory" className="navlink" onClick={closeMobileNavbar}><FormattedMessage id="mentoring" /></NavLink>
          <NavLink to="/web3-resources" className="navlink" onClick={closeMobileNavbar}><FormattedMessage id="more-resources" /></NavLink>
        </div>}
      </nav>
    )
  }

}

export default Navbar