const url = process.env.REACT_APP_API_URL ||"http://localhost:4000/"

// const url = "http://192.168.1.130:4000/"
export const SIGN_ENDPOINTS = {
  signin: url + "sign-in",
  signup: url + "sign-up",
  signGoogle: url + "sign-google",
  validToken: url + "valid-token",
}

export const COURSES_ENDPOINTS = {
  getCourse: url + "course",
  getCourses: url + "courses",
  buyCourse: url + "buy-course",
  getCourseByEmail: url + "user-course",
  getCourseCompletedByEmail: url + "completed/course",
  getOwnership: url + "course-ownership",
  getChaptersByCourse: url + "course/chapters",
  verifyTestQuestions: url + "course",
  isCompletedCourse: url + "is-completed-course"
}

export const USERS_ENDPOINTS = {
  getUser: url + "user",
}

export const USERSPOINTS_ENDPOINTS = {
  getAllPoints: url + "all-user-points",
  modifyPoints: url + "modify-points",
}

export const SURVEYS_OPTIONAL = {
  insertUserAnswer: url + "survey/optional/answer",
}

export const SURVEYS_MANDATORY = {
  insertUserAnswer: url + "survey/mandatory/answer",
}

export const RESET_PASSWORD = {
  orderResetPassword: url + "reset-password",
  verifyCode: url + "verify-code",
  setNewPassword: url + "new-password",
  verifyResetToken: url + "verify-resetToken",
}

export const STRIPE = {
  getPublicKey: url + "get-stripePublicKey",
  createPaymentIntent: url + "create-payment-intent"
}
