import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import useTitleDescriptionByLanguage from '../../hooks/useTitleDescriptionByLanguage'
import CryptoPayment from "./CryptoPayment"
import { useNavigate } from "react-router-dom"
import addBuyCourseToDB from "./addBuyCourseToDB"
import '../../styles/buyModal.css'
import StripePayment from "./StripePayment"

const BuyModal = ({ setIsBuyModalOpen, course }) => {

  const [isPayWitCrypto, setIsPayWithCrypto] = useState(false)
  const [isPayWithCard, setIsPayWithCard] = useState(false)
  const { title } = useTitleDescriptionByLanguage(course)
  const navigate = useNavigate()
  const closeHandler = () => { setIsBuyModalOpen(false) }
  const payHandler = () => {
    setIsPayWithCard(true)
    setIsPayWithCrypto(false)
  }
  const payCryptoHandler = () => {
    setIsPayWithCard(false)
    setIsPayWithCrypto(true)
  }
  const getFreeCourseHandler = async () => {
    await addBuyCourseToDB(course)
    closeHandler()
    navigate(`/personal-area/courses/${course.course_id}`)

  }

  return (
    <div className="modal">
      <div className={ isPayWitCrypto || isPayWithCard ? "buy-modal-content extra-height": "buy-modal-content" } >
        <header className="header-sign-modal">
          <button className="close-btn" onClick={closeHandler}> <FormattedMessage id="x-close" /></button>
        </header>
        <section className="course-info-in-modal">
          <h3 className="buy-modal-course-title">{title}</h3>
          {/* <p className="buy-modal-course-descrip">{description}</p> */}
          <h4 className="buy-modal-course-price">{course?.price} $</h4>
        </section>
        {course.price === "0.00" && <section className="buy-btns-container">
          <button className="buy-crypto-btn" onClick={getFreeCourseHandler}><FormattedMessage id="get-free-course" /></button>
        </section>}
        {!course.price === "0.00" && <section className="buy-btns-container">
          <button className="buy-crypto-btn" onClick={payHandler}><FormattedMessage id="pay" /></button>
          <button className="buy-crypto-btn" onClick={payCryptoHandler}><FormattedMessage id="pay-with-crypto" /></button>
        </section>}
        {isPayWitCrypto && <CryptoPayment course={course} isPayWitCrypto={isPayWitCrypto} />}
        {isPayWithCard && <StripePayment course={course} isPayWithCard={isPayWithCard} />}
      </div>
    </div>
  )

}

export default BuyModal