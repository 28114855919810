import { SURVEYS_OPTIONAL } from "../../../api/endpoints"
import { POINTS_OPTIONS, POINTS_AMOUNT } from "../../../constants"
import addUserPoints from "../../AddUserPoints"

const submitOptionalSurvey = async ({ surveyInfo, setError, setSent }) => {
  const token = window.localStorage.getItem("token")
  const email = window.localStorage.getItem("email")
  if(surveyInfo.answers.some(answer => answer === undefined)) setError(true)
  else {
    setError(false)
    await fetch(SURVEYS_OPTIONAL.insertUserAnswer, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: token,
      },
      body: JSON.stringify({
        email,
        surveyId: surveyInfo.survey_id,
        answer: surveyInfo.answers,
        textAnswer: surveyInfo.textAnswer,
      }),
    })
      .then(res => res.json())
      .then(async data => {
        if (data?.state === 404) {
          setError(true)
          setSent(false)
        } else {
          setError(false)
          setSent(true)
          await addUserPoints(POINTS_OPTIONS.form, POINTS_AMOUNT.form)
        }
      })
      .catch(e => {
        setError(true)
      })
  }
}

export default submitOptionalSurvey
