import { FormattedMessage } from "react-intl"
import sadFace from "../../images/sadFace.svg"
const NoCourse = () => {
  return (
    <div className="sad-no-courses">
      <img src={sadFace} alt="sad-face" />
      <span className="sad-no-courses-text "><FormattedMessage id="not-bought-yet" /> </span>
    </div>
  )
}
export default NoCourse