import ChaptersInformation from "./ChaptersInformation";
import { useGetData } from "../../hooks/useGetData";
import { COURSES_ENDPOINTS } from "../../api/endpoints";
import { FormattedMessage } from "react-intl";

const IndividualCourseChapterSection = ({ id }) => {
  const { result: chapters, loaded: chaptersLoaded } = useGetData(`${COURSES_ENDPOINTS.getChaptersByCourse}/${id}`);
  return (
    <>
      <h2 className="title-program"><FormattedMessage id="chapters" /></h2>
      <section className="chapters-container">
        <ChaptersInformation chapters={chapters} loaded={chaptersLoaded} />
      </section>
    </>
  )
}

export default IndividualCourseChapterSection