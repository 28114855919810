import React, { Suspense, useEffect, useState } from "react"
import { COURSES_ENDPOINTS } from "../../api/endpoints"
import ChapterListItem from "./ChapterListItem"
import { FormattedMessage } from "react-intl"
import Loading from '../Loading'
import sadFace from '../../images/sadFace.svg'
import { addFinalSurvey } from "./chaptersListHelper"
import { useSelector } from "react-redux"
import { useGetData } from "../../hooks/useGetData"
import { getUserAdvance } from "../../helpers/progressHelper"
import useChapterForCourse from "../../hooks/useChaptersForCourse"

export const ChaptersList = ({ courseId }) => {
  const { chapters, loaded, error } = useChapterForCourse(courseId)
  const chaptersAndSurvey = addFinalSurvey(chapters, courseId)

  const urlCourse = `${COURSES_ENDPOINTS.getCourseCompletedByEmail}?email=${window.localStorage.getItem("email")}&courseid=${courseId}`
  const { result: courseBought, loaded: courseLoaded } = useGetData(urlCourse, false)
  const courseCompleted =  courseBought?.completed

  const userProgress = useSelector(s => s.progress)
  const progressForThisCourse = userProgress?.filter(c => +c?.courseId === +courseId)
  const maxProgress = progressForThisCourse.length > 0 ? Math.max(...progressForThisCourse?.map(c => c?.completed)) : 0
  const [userAdvance, setUserAdvance] = useState(0)
  useEffect(() => {
    const advance = getUserAdvance(maxProgress, courseCompleted);
    setUserAdvance(advance);
  }, [maxProgress, courseCompleted]);

  return (
    <>

      {loaded && courseLoaded && !error &&
        chaptersAndSurvey?.map((chapter, index) =>
          <Suspense fallback={<> </>} key={chapter.title_en} >
            <ChapterListItem key={chapter.title_en} chapter={chapter} index={index} total={chapters.length} userAdvance={userAdvance} setUserAdvance={setUserAdvance} />
          </Suspense>)}

      {!loaded && <Loading isBig={true} text={"Waiting"} />}

      {error && (
        <div className="sad-no-courses">
          <img src={sadFace} alt="sad-face" />
          <span className="sad-no-courses-text ">
            <FormattedMessage id="error-loading-chapters" />
          </span>
        </div>
      )}
    </>
  )
}