import { useParams } from "react-router-dom"
import { COURSES_ENDPOINTS } from "../api/endpoints"
import { useGetData } from "../hooks/useGetData"
import useTitleDescriptionByLanguage from "../hooks/useTitleDescriptionByLanguage"
import { ChaptersList } from "../components/PersonalAreaCourses/ChaptersList"
import { Suspense } from "react"
import Loading from "../components/Loading"
import '../styles/personalAreaCourses/personalAreaCourses.css'

const PersonalAreaCourses = () => {
  const { id: courseId } = useParams()
  const { result: course } = useGetData(`${COURSES_ENDPOINTS.getCourse}/${courseId}`)
  const { title } = useTitleDescriptionByLanguage(course)
  return (
    <main className="main-personal-area-courses">
      <h2 className="individual-course-title">{title}</h2>
      <Suspense fallback={<Loading isBig={true} text={"Waiting..."} />}>
        <section className="personal-area-chapters-container">
          <ChaptersList courseId={courseId} />
        </section>
      </Suspense>
    </main>)
}

export default PersonalAreaCourses