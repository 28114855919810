
import { Navigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import useFetch from 'fetch-suspense'
import { SIGN_ENDPOINTS, COURSES_ENDPOINTS } from "../api/endpoints";

// Any redirect/Navigate must be synchronous operation. Otherwhise, the first render the navigate is executed and the async task doesn´t have any effect because
// the component has dissappeared
const ProtectedCourseRoute = ({ children }) => {
  const { id: courseId } = useParams();
  const location = useLocation();
  const token = window.localStorage.getItem("token");
  const email = window.localStorage.getItem("email")
  const validTokenRes = useFetch(`${SIGN_ENDPOINTS.validToken}?token=${token}&email=${email}`)
  const isValidToken = validTokenRes?.data
  // isOwner in an object in case the response is an error. Otherwise, returns true or false
  const isOwnerRes = useFetch(`${COURSES_ENDPOINTS.getOwnership}?id=${courseId}&email=${email}`, { method: "get", headers: { authorization: token } })
  const isOwner = isOwnerRes?.data

  if (!isValidToken || (!isOwner || (typeof isOwner === "object"))) {
    return (
      <Navigate
        to={`/courses/${courseId}`}
        state={{ from: location }}
        replace
      />
    );
  }
  return children;
};

export default ProtectedCourseRoute;
