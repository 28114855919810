import React, { useState, useEffect } from "react";
import { STRIPE } from "../../api/endpoints"
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm"
import "../../styles/individualCourse/stripePayment.css"
import Loading from "../Loading";

const StripePayment = ({ course, isPayWithCard }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const price = course?.price

  useEffect(() => {
    const getStripePublicKey = async () => {
      const response = await fetch(STRIPE.getPublicKey, {
        method: "get",
        headers: { authorization: window.localStorage.getItem("token") },
      })
      const { publicKey } = await response.json();
      setStripePromise(loadStripe(publicKey));
    }
    try {
      getStripePublicKey()
    } catch (e) {
      console.log("Error getting stripe public key", e)
    }
  }, []);

  useEffect(() => {
    const createPaymentIntent = async () => {
      const response = await fetch(STRIPE.createPaymentIntent, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          coursePrice: price
        }),
      })
      const { stripeSecretKey } = await response.json();
      setClientSecret(stripeSecretKey);
    }
    try {
      createPaymentIntent()
    } catch (e) {
      console.log("Error creating payment intent", e)
    }
  }, [price]);


  return (
    <>
      {(!clientSecret || !stripePromise) && <Loading />}
      {isPayWithCard && clientSecret && stripePromise && (
        <div className="stripe-payment-container">
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <StripeCheckoutForm course={course} />
          </Elements>
        </div>
      )}
    </>
  );
};

export default StripePayment;