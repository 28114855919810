export const POST_SUMMARY = [
  {
    filename: "post3",
    title: "Understanding the Reentrancy Attack in Smart Contracts",
    description: "Smart contracts are a fundamental building block of blockchain applications, enabling trustless and automated interactions...",
    duration: "3 min",
    date: "September 2023",
  },
  {
    filename: "post4",
    title: "Multichain Protocols and Security Considerations",
    description: "This comprehensive guide aims to provide an in-depth exploration of key considerations, best practices, and potential pitfalls associated with cross-chain deployment, empowering developers to navigate this complex terrain with confidence...",
    duration: "10 min",
    date: "September 2023",
  },
  {
    filename: "post5",
    title: "Funding Rate and Perpetual Protocols",
    description: "The funding rate of a perpetual contract in the blockchain space refers to the mechanism used to maintain the contract’s price alignment with the underlying asset or index it tracks.",
    duration: "4 min",
    date: "September 2023",
  },
  {
    filename: "post6",
    title: "Chainlink VRF and Security Considerations",
    description: "You may have seen lots of reports about Oracle’s issues, especially for avoiding retrieving stale prices. Oracles do provide other services that need to be checked to be secure. What about CHAINLINK VRF? How it works and security considerations.",
    duration: "7 min",
    date: "September 2023",
  },
]

export const UNUSED_POST = [  {
  filename: "post1",
  title: "Introduction to blockchain",
  description: "Blockchain technology is revolutionizing the way we think about data, transactions, and trust. Originally created to support cryptocurrencies...",
  duration: "5 min",
  date: "September 2023",
},
{
  filename: "post2",
  title: "Introduction to DEFI",
  description: "Decentralized Finance, or DeFi, is one of the most exciting and transformative developments in the blockchain and cryptocurrency space...",
  duration: "4 min",
  date: "September 2023",
},]

