export const addFinalSurvey = (chapters, courseId) => {
  let chaptersAndSurvey
  if (chapters) {
    const parsedChapters = Array.isArray(chapters) ? chapters : JSON.parse(chapters)
    const mandatorySurveyChapter = {
      course_id: courseId,
      description_en: "This is the final survey to provide some feedback about the course.",
      description_esp: "Encuesta final del curso para aportar feedback sobre el curso.",
      duration: "5",
      title_en: "Final survey",
      title_esp: "Encuesta final",
      isSurvey: true,
    }
    chaptersAndSurvey = parsedChapters ? [...parsedChapters, mandatorySurveyChapter] : [mandatorySurveyChapter]
  }
  return chaptersAndSurvey
}
