

export class UserExistError extends Error {
  constructor(message) {
    super(message)
    this.name = "UserExistError"
    this.message = message
  }
}

export class EmailFormatError extends Error {
  constructor(message) {
    super(message)
    this.name = "EmailFormatError"
    this.message = message
  }
}

export class ConfirmPasswordError extends Error {
  constructor(message) {
    super(message)
    this.name = "ConfirmPasswordError"
    this.message = message
  }
}