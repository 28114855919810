import { useState, useEffect } from "react"
import { SIGN_ENDPOINTS } from "../api/endpoints"

export const useValidToken = () => {
  const [isValidToken, setIsValidToken] = useState(false)
  const token = window.localStorage.getItem("token")
  const email = window.localStorage.getItem("email")
  useEffect(() => {
    if (token) {
      fetch(`${SIGN_ENDPOINTS.validToken}?token=${token}&email=${email}`)
        .then(res => res.json())
        .then(data => setIsValidToken(data?.data))
    }
  }, [token, email])

  return { token, isValidToken }
}
