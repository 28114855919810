import { useLocation, useParams, Navigate } from "react-router-dom";
import useFetch from 'fetch-suspense'
import { COURSES_ENDPOINTS } from "../api/endpoints";

const ProtectedMintRoute = ({ children }) => {
  const { courseId } = useParams();
  const location = useLocation();
  const email = window.localStorage.getItem("email")
  const token = window.localStorage.getItem("token")
  const isCompletedCourse = useFetch(`${COURSES_ENDPOINTS.isCompletedCourse}?courseid=${courseId}&email=${email}`, { method: "get", headers: { authorization: token }, lifespan: 10 })
  if (!isCompletedCourse || !email) {
    return (
      <Navigate
        to={`/`}
        state={{ from: location }}
        replace
      />
    );
  }
  return children;
}

export default ProtectedMintRoute