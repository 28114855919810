// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  height: 80px;
}

.footer-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.footer-logo {
  width: 40px;
  height: 30px;
}

.footer-company-name {
  font-size: 14px;
  font-family: var(--font-family);
  color: grey;
}

.footer-links {
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  font-family: var(--font-family);
  color: grey;
}

.socials-links {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

@media (max-width: 600px) {
  .footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  height: auto;
  padding-bottom: 20px;
}
.footer-company-name {
  font-size: 1rem;
}

.footer-links {
  font-size: 1rem;
}

}`, "",{"version":3,"sources":["webpack://./src/styles/footer.css"],"names":[],"mappings":"AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,+BAA+B;EAC/B,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,iBAAiB;EACjB,+BAA+B;EAC/B,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE;EACA,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,YAAY;EACZ,oBAAoB;AACtB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA","sourcesContent":["@import url(\"global.css\");\n.footer {\n  position: relative;\n  bottom: 0;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 50px;\n  height: 80px;\n}\n\n.footer-logo-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 4px;\n}\n\n.footer-logo {\n  width: 40px;\n  height: 30px;\n}\n\n.footer-company-name {\n  font-size: 14px;\n  font-family: var(--font-family);\n  color: grey;\n}\n\n.footer-links {\n  text-decoration: none;\n  font-size: 14px;\n  font-weight: bold;\n  font-family: var(--font-family);\n  color: grey;\n}\n\n.socials-links {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n@media (max-width: 600px) {\n  .footer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 12px;\n  height: auto;\n  padding-bottom: 20px;\n}\n.footer-company-name {\n  font-size: 1rem;\n}\n\n.footer-links {\n  font-size: 1rem;\n}\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
