import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { SIGN_ENDPOINTS } from "../../api/endpoints"
import { useNavigate } from "react-router-dom"
import { SIGN_ERROR_NAMES, HTTP_CODES } from "../../constants"
import '../../styles/signInOut/signInForm.css'


const SignForm = ({ alreadyHaveAccount, closeHandler }) => {
  const { signInError, signUpError, emailFormatError, networkError, internalServerError } = SIGN_ERROR_NAMES
  const SHOWING_LOGIN_MESSAGE_MS = 3000
  const [formInfo, setFormInfo] = useState({
    email: undefined,
    password: undefined
  })
  const [error, setError] = useState({
    [signUpError]: undefined,
    [signInError]: undefined,
    [emailFormatError]: undefined,
    [networkError]: undefined
  })
  const [token, setToken] = useState(undefined)
  const navigate = useNavigate()
  const submitHandle = async (e) => {
    e.preventDefault()
 
    const url = alreadyHaveAccount ? SIGN_ENDPOINTS.signin : SIGN_ENDPOINTS.signup
    const body = JSON.stringify(formInfo)
    const fetchOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body
    }
    try {
      const res = await fetch(url, fetchOptions)
      if (res.status === HTTP_CODES.ok) {
        const data = await res.json()
        if (data.token) {
          window.localStorage.setItem("token", data.token)
          window.localStorage.setItem("email", formInfo.email)
          setToken(data.token)
        }
      } else if (res.status === HTTP_CODES.unauthorized) {
        const data = await res.json()
        setError({ ...error, [signInError]: data.error })
      } else if (res.status === HTTP_CODES.conflict) {
        const data = await res.json()
        setError({ ...error, [signUpError]: data.error })
      } else if (res.status === HTTP_CODES.server) {
        const data = await res.json()
        setError({ ...error, [internalServerError]: data.error })
      }
    } catch (error) {
      setError({ ...error, [networkError]: e.message })
    } finally {
      setTimeout(() => {
        if (window.localStorage.getItem("token")) {
          closeHandler()
          navigate("/personal-area")
        }
      }, SHOWING_LOGIN_MESSAGE_MS)
    }

  }

  const handleFormInfo = (ev, type) => {
    if (type === "email") {
      setFormInfo({ ...formInfo, [type]: (ev.target.value).toLowerCase() })
      setError({})
      if (error.emailError) setError({ ...error, [emailFormatError]: undefined })
    }
    else {
      setFormInfo({ ...formInfo, [type]: ev.target.value })
      setError({})
    }
  }

  return (
    <>
      <form onSubmit={submitHandle} className="sign-in-form-container">
        <label className="form-signin-label"><FormattedMessage id="email" /></label>
        <input type="email" className="form-signin-input" id="sign-in-email" onChange={(ev) => handleFormInfo(ev, "email")} />
        <label className="form-signin-label"><FormattedMessage id="password" /></label>
        <input type="password" className="form-signin-input" id="sign-in-password" onChange={(ev) => handleFormInfo(ev, "password")} />
        <button className="form-signin-button" type="submit" disabled={!formInfo?.email || !formInfo?.password}><FormattedMessage id={alreadyHaveAccount ? "sign-in" : "sign-up"} /></button>

        {token ? <span className="login-correct"><FormattedMessage id="login-correct" /></span>
          : <>
            {error[emailFormatError] && <span className="error-message-sign "><FormattedMessage id="error-with-mail" /></span>}
            {error[signUpError] && <span className="error-message-sign "><FormattedMessage id="error-with-user-exist" /></span>}
            {error[signInError] && <span className="error-message-sign "><FormattedMessage id="error-email-password" /></span>}
            {error[networkError] && <span className="error-message-sign "><FormattedMessage id="error-sign-network" /></span>}
          </>}
      </form>
    </>
  )
}

export default SignForm