import { Link } from 'react-router-dom'
import { FormattedMessage } from "react-intl"
import logo from '../images/boosthub/transparentSmallLogo.png'
import '../styles/footer.css'

const Footer = () => {
  return (
    <footer className='footer'>
      <article className="footer-logo-container">
        <img src={logo} alt='BoostHub-logo' className='footer-logo' />
        <span className='footer-company-name'>2023 © BoostHub</span>
      </article>
      <>
        <Link className="footer-links" to="/condition-terms"><FormattedMessage id="conditions-terms" /></Link>
        <Link className="footer-links" to="/privacy-policy"><FormattedMessage id="privacy-policy" /></Link>
        <Link className="footer-links" to="/help"><FormattedMessage id="help" /></Link>
        <a className="footer-links" href="https://twitter.com/0xBoostHub" target="_blank" rel='noreferrer'><FormattedMessage id="twitter" /></a>
        <a className="footer-links" href="https://discord.com/invite/Jg3PVWj5h7" target="_blank" rel='noreferrer'><FormattedMessage id="discord" /></a>
      </>
    </footer>)
}

export default Footer