import { useParams, useNavigate } from "react-router-dom"
import { COURSES_ENDPOINTS } from "../../api/endpoints";
import { FormattedMessage } from "react-intl";
import { useGetData } from "../../hooks/useGetData";


const EnrollAccessButton = ({ isValidToken, enrollNowHandler }) => {
  const { id } = useParams();
  const email = window.localStorage.getItem("email")
  const navigate = useNavigate()

  const accessCourseHandler = () => {
    if (window.localStorage.getItem("token"))
      navigate("/personal-area/courses/" + id)
  };


  const { result, loaded } = useGetData(`${COURSES_ENDPOINTS.getOwnership}?id=${id}&email=${email}`, false)
  const isOwner = result?.data
  if (!loaded) return <></>
  if (!email || !isValidToken || (!isOwner && loaded)) {
    return <button className="enroll-btn" onClick={enrollNowHandler}><FormattedMessage id="enroll-now" /></button>
  }

  if (isOwner && loaded) return <button className="enroll-btn" onClick={accessCourseHandler}><FormattedMessage id="access-course" /></button>

}

export default EnrollAccessButton