// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-personal-area-courses {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  min-height: 100vh;
  padding-bottom: 40px;
}

.individual-course-title{
  color: white;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 2.5px;
}
.personal-area-chapters-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  overflow: auto;
  position: relative;
  width: 1000px;
}

@media (max-width: 600px) {
  .individual-course-title{
  text-align: center;
  font-size: 1.5rem;
  letter-spacing: 1.5px;
  width: var(--mobile-max-text-width);
}
}

`, "",{"version":3,"sources":["webpack://./src/styles/personalAreaCourses/personalAreaCourses.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,cAAc;EACd,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE;EACA,kBAAkB;EAClB,iBAAiB;EACjB,qBAAqB;EACrB,mCAAmC;AACrC;AACA","sourcesContent":["@import url('../global.css');\n\n.main-personal-area-courses {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  margin-top: 50px;\n  min-height: 100vh;\n  padding-bottom: 40px;\n}\n\n.individual-course-title{\n  color: white;\n  font-size: 2rem;\n  font-weight: bold;\n  letter-spacing: 2.5px;\n}\n.personal-area-chapters-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 20px;\n  overflow: auto;\n  position: relative;\n  width: 1000px;\n}\n\n@media (max-width: 600px) {\n  .individual-course-title{\n  text-align: center;\n  font-size: 1.5rem;\n  letter-spacing: 1.5px;\n  width: var(--mobile-max-text-width);\n}\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
