import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useGetData } from '../hooks/useGetData'
import { COURSES_ENDPOINTS } from '../api/endpoints'
import { useItemByLanguage } from '../hooks/useTitleDescriptionByLanguage'
import { HTTP_CODES } from '../constants'
import success from '../images/success-icon.svg'
import { useDispatch } from "react-redux"
import { updateProgress } from '../redux/progressCourseSlice'
import '../styles/testModal.css'


const answerValueSelector = (i) => {
  let value
  switch (i) {
    case 0:
      value = "A"
      break;
    case 1:
      value = "B"
      break;
    case 2:
      value = "C"
      break;
    default:
      value = "D"
      break;
  }
  return value
}

const TestModal = ({ setIsOpenTestModal, setUserAdvance, chapterId, courseId, userAdvance }) => {
  const [loaded, setLoaded] = useState(false)
  const [validated, setValidated] = useState(undefined)
  const [error, setError] = useState(undefined)
  const { result: testInfo } = useGetData(`${COURSES_ENDPOINTS.verifyTestQuestions}/${courseId}/chapter/${chapterId}`)
  const distpach = useDispatch()

  const [userAnswers, setUserAnswers] = useState({
    0: undefined,
    1: undefined,
    2: undefined,
  })

  const questions = useItemByLanguage(testInfo?.questions_en, testInfo?.question_esp)
  const answers = useItemByLanguage(testInfo?.answer_en, testInfo?.answer_esp)
  const currentChapterIndex = testInfo?.indexForChapter
  const closeHandler = () => setIsOpenTestModal(false)
  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      const res = await fetch(`${COURSES_ENDPOINTS.verifyTestQuestions}/${courseId}/chapter/${chapterId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: window.localStorage.getItem("token"),
        },
        body: JSON.stringify({
          responses: userAnswers,
          email: window.localStorage.getItem("email")
        }),
      })
      if (res.status === HTTP_CODES.ok) {
        const data = await res.json()
        setValidated(data?.isCorrect)
        setUserAdvance(data?.newCompletedValue)
        distpach(updateProgress({courseId: courseId, completed: data.newCompletedValue}))
      } else if (res.status === HTTP_CODES.notfound) {
        const resError = await res.json()
        setError(resError)
      } else {
        const resError = await res.json()
        setError(resError)
      }

    } catch (error) {
      console.error("Error posting test responses.", error.message)
      setError(error)
    }
    finally {
      setLoaded(true)
    }

  }

  const handleOption = (ev, i) => {
    const res = ev.target.value
    setUserAnswers({ ...userAnswers, [i]: res })
    setError(undefined)
    setValidated(false)
    setLoaded(false)
  }

  return (
    <div className="modal-test">
      <div className="test-modal-container">
        <header className='youtube-modal-header'>
          <button onClick={closeHandler} className='youtube-modal-header-button'><FormattedMessage id="x-close" /></button>
        </header>
        <form className="questions-container" onSubmit={submitHandler}>
          {questions?.map((question, i) =>
            <article className="question" key={question.questions_en}>
              <span className="question-statement">{i + 1}. {question}</span>
              {answers[i].map((answer, answerIndex) => <label className='answer' key={`${i}-${answer}`}>
                <input type="radio"
                  className="radio-test"
                  name={`question-${i + 1}`}
                  onChange={(ev) => handleOption(ev, i)}
                  value={answerValueSelector(answerIndex)} />  {` ${answerValueSelector(answerIndex)}) ${answer}`}
              </label>)}
            </article>)}
          <button className='save-send-btn' type="submit" disabled={userAdvance > currentChapterIndex}><FormattedMessage id="save-and-send" /></button>
        </form>
        <div className='test-result-container'>
          {validated && loaded && <strong className='approved-test'>
            <img src={success} alt="success" className="test-modal-imgs" /><FormattedMessage id="test-approved" /></strong>}
            {validated && loaded && <button className='approved-test' onClick={() => setIsOpenTestModal(false)}><FormattedMessage id="continue" /></button>}
          {!validated && loaded && !error && <strong className='incorrect-answer'><FormattedMessage id="incorrect-answer" /></strong>}
          {error && loaded && <strong className='incorrect-answer'><FormattedMessage id="incorrect-answer" /> </strong>}
        </div>
      </div>
    </div>
  )
}

export default TestModal