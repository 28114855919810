import { useSelector } from "react-redux"
import { useState } from "react"
import leftArrow from '../../images/leftArrow.svg'
import rightArrow from '../../images/rightArrow.svg'
import Loading from "../Loading"
import '../../styles/individualCourse/chaptersInfoSlideShow.css'


const ChaptersInformation = ({ chapters, loaded }) => {
  const lang = useSelector((s) => s.language)
  const [chapterToShow, setChapterToShow] = useState(0)
  const handleSlideShow = (isToRight) => {
    if (isToRight) setChapterToShow(s => s === (chapters.length - 1) ? 0 : s + 1)
    else setChapterToShow(s => s === 0 ? chapters.length - 1 : s - 1)
  }

  return (
    <>
      {loaded ? <div className="slideshow-container">
        <button className="btn arrow-left" onClick={() => handleSlideShow(false)}><img src={leftArrow} alt="left-arrow" className="arrows-img" /></button>
        <ChaptersInfoSlideShow chapter={chapters[chapterToShow]} lang={lang} index={chapterToShow} />
        <button className="btn arrow-right" onClick={() => handleSlideShow(true)} ><img src={rightArrow} alt="left-arrow" className="arrows-img" /></button>
      </div>
        : <Loading isBig={false} text={"Loading chapters"} />}
    </>
  )
}

const ChaptersInfoSlideShow = ({ chapter, lang, index }) => {
  return (
    <>
      <h4 className="chapter-slideshow-title">{index + 1}. {lang === "en" ? chapter.title_en : chapter.title_esp}  <span className="chapter-slideshow-duration"> ({chapter.duration} min)</span></h4>

      <p className="chapter-slideshow-description">{lang === "en" ? chapter.description_en : chapter.description_esp}</p>
    </>
  )
}
export default ChaptersInformation