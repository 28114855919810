import SignForm from "./SignForm";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import "../../styles/signInOut/signModal.css";
// import LoginGoogle from "./GoogleLogin";

const SignModal = ({ alreadyHaveAccount, setAlreadyHaveAccount, setIsOpenSignModal }) => {

  const navigate = useNavigate()
  const closeHandler = () => {
    setIsOpenSignModal(false);
  };

  const alreadySignedHandler = () => {
    setAlreadyHaveAccount(true);
  };

  const forgottenPasswordHandler = () => {
    closeHandler()
    navigate("/forgotten-password")
  }

  const SignUp = <main className="main-sign">
    <h3 className="sign-title-modal"><FormattedMessage id="create-new-account" /></h3>
    <section className="sign-form-external-container">
      <SignForm
        alreadyHaveAccount={alreadyHaveAccount}
        closeHandler={closeHandler}
      />
    </section>

    <button className="forgotten-password" onClick={alreadySignedHandler}> <FormattedMessage id="already-sign" /></button>
    {/* <LoginGoogle closeHandler={closeHandler} /> */}
  </main>

  const SignIn = <main className="main-sign">
    <h3 className="sign-title-modal"><FormattedMessage id="access-with-account" /></h3>
    <section className="sign-form-external-container">
      <SignForm alreadyHaveAccount={alreadyHaveAccount} closeHandler={closeHandler} />
    </section>
    {alreadyHaveAccount && <button className="forgotten-password" onClick={forgottenPasswordHandler}><FormattedMessage id="forget-your-password" /></button>}
    {/* <LoginGoogle closeHandler={closeHandler} /> */}
  </main>

  return (
    <div className="modal">
      <div id="sign-modal-content">
        <header className="header-sign-modal">
          <button className="close-btn" onClick={closeHandler}> <FormattedMessage id="x-close" /></button>
        </header>
        {alreadyHaveAccount ? SignIn : SignUp}
      </div>
    </div>
  );
};

export default SignModal;
