import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import submitMandatorySurveyAnswer from "../../helpers/surveys/mandatorySurveys/SubmitMandatorySurveyAnswer";
import redcross from "../../images/redcross.svg";
import "../../styles/surveyForm.css";

const MandatorySurveyForm = ({ courseId }) => {
  const [surveyInfo, setSurveyInfo] = useState({ courseId: courseId, answer: "" });
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);

  const submitHandle = async (e) => {
    e.preventDefault();
    await submitMandatorySurveyAnswer(surveyInfo, setError, setSent);
  };

  return (
    <>
      <form onSubmit={submitHandle} className="survey-container">
        <label className="form-survey-label"> <FormattedMessage id="mandatory-survey" /></label>
        <textarea
          className="form-survey-textarea"
          value={surveyInfo.answer}
          onChange={(ev) => setSurveyInfo({ ...surveyInfo, answer: ev.target.value })} />
        <button className="form-survey-button" type="submit"><FormattedMessage id="send-survey" /></button>

        {sent && (
          <div className="error-msg-container">
            <span className="survey-sent-correct"> <FormattedMessage id="success-survey" /></span>
          </div>
        )}
        {error && (
          <div className="error-msg-container">
            <img src={redcross} alt="Error" className="cross-img" />
            <span className="survey-sent-incorrect "><FormattedMessage id="error-survey" /></span>
          </div>
        )}
      </form>
    </>
  );
};

export default MandatorySurveyForm;
