import { Suspense } from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Landing from "./routes/Landing"
import Courses from "./routes/Courses"
import Navbar from "./components/Navbar"
import { IntlProvider } from "react-intl"
import { useSelector } from "react-redux"
import english from "./locales/english.json"
import spanish from "./locales/spanish.json"
import WrappedIndividualCourse from "./routes/IndividualCourse"
import PersonalArea from "./routes/PersonalArea"
import PersonalAreaCourses from "./routes/PersonalAreaCourses"
import ProtectedCourseRoute from "./routes/ProtectedCoursesRoute"
import ForgotPassword from "./routes/ForgotPassword"
import Blog from "./routes/Blog"
import BlogIndividualWrapped from "./routes/BlogIndividual"
import Footer from "./components/Footer"
import Mentory from "./routes/Mentory"
import ConditionTerms from "./routes/ConditionTerms"
import PrivacyPolicy from "./routes/PrivacyPolicy"
import Help from "./routes/Help"
import MintNft from "./routes/MintNft"
import ProtectedMintRoute from "./routes/ProtectedMintRoute"

import "./styles/global.css"


function App() {
  const language = useSelector(s => s.language)

  return (
    <IntlProvider locale={language === "en" ? "en" : "esp"} messages={language === "en" ? english : spanish}>
      <BrowserRouter>
        <div className="global">
          <Navbar />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/forgotten-password/" element={<ForgotPassword />} />
            <Route path="/web3-mentory" element={<Mentory />} />
            <Route path="/courses" element={<Courses />} />

            <Route path="/courses/:id" element={<WrappedIndividualCourse />} />
            <Route path="/web3-resources" element={<Blog />} />
            <Route path="/blog/:title" element={<BlogIndividualWrapped />} />
            <Route
              path="/mint-nft/:courseId"
              element={
                <Suspense fallback={<>Loading..</>}>
                  <ProtectedMintRoute>
                    <MintNft />
                  </ProtectedMintRoute>
                </Suspense>
              }
            />
            <Route path="/personal-area" element={<PersonalArea />} />
            <Route
              path="/personal-area/courses/:id"
              element={
                <Suspense fallback={<>Loading..</>}>
                  <ProtectedCourseRoute>
                    <PersonalAreaCourses />
                  </ProtectedCourseRoute>
                </Suspense>
              }
            />
            <Route path="/condition-terms/" element={<ConditionTerms />} />
            <Route path="/privacy-policy/" element={<PrivacyPolicy />} />
            <Route path="/help/" element={<Help />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </IntlProvider>
  )
}

export default App
