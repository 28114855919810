import React, { useEffect, useState } from "react"
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import '../styles/lazyPost.css'

const Help = () => {
  const [post, setPost] = useState(undefined)

  useEffect(() => {
    import('../blogContent/help.md')
      .then(res => {
        fetch(res.default)
          .then(res => res.text())
          .then(res => setPost(res))
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));

  }, []);

  return (<div className="lazy-post-container">
    {post && <ReactMarkdown remarkPlugins={[gfm]} children={post} />}
  </div>)
}

export default Help