import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import '../../styles/landing.css'

const Mentoring = () => {
  const navigate = useNavigate()
  return (
    <section className="landing-courses-section">
      <h2 className="landing-title"><FormattedMessage id="mentoring" /></h2>
      <span className="text-landing-courses"><FormattedMessage id="mentoring-text" /></span>
      <button className="access-courses-from-landing" onClick={() => navigate("/web3-mentory")} ><FormattedMessage id="access-to-the-mentory" /></button>
    </section>
  )
}

export default Mentoring