import { SURVEYS_MANDATORY } from "../../../api/endpoints"
import addUserPoints from "../../AddUserPoints"
import { POINTS_OPTIONS, POINTS_AMOUNT } from "../../../constants"

const submitMandatorySurveyAnswer = async (surveyInfo, setError, setSent) => {
  const token = window.localStorage.getItem("token")
  const email = window.localStorage.getItem("email")
  if (surveyInfo.answer === '') setError(true)
  else {
    setError(false)
  await fetch(SURVEYS_MANDATORY.insertUserAnswer, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
    body: JSON.stringify({
      email: email,
      courseId: surveyInfo.courseId,
      answer: surveyInfo.answer,
    }),
  })
    .then(res => res.json())
    .then(async data => {
      if (data?.state === 404) {
        setError(true)
        setSent(false)
      } else {
        setError(false)
        setSent(true)
        await addUserPoints(POINTS_OPTIONS.completedCourse, POINTS_AMOUNT.completedCourse)
      }
    })
    .catch(e => {
      setError(true)
    })
  }
}

export default submitMandatorySurveyAnswer
