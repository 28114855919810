import React, { useState } from "react"
import { useSelector } from "react-redux"
import downArrow from '../../images/pointerDown.svg'
import VideoPlayerModal from "../VideoPlayerModal"
import TestModal from "../TestModal"
import useModal from '../../hooks/useModal'
import SurveyModal from "../Surveys/SurveyModal"
import OpenedChapter from "./OpenedChapter"
import '../../styles/personalAreaCourses/chapterListItem.css'

const ChapterListItem = ({ chapter, index, userAdvance, setUserAdvance }) => {
  const lang = useSelector((s) => s.language)
  const [isOpenChapter, setIsOpenChapter] = useState(false)
  const [isVideoOpen, setIsVideoOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState()
  const [isOpenTestModal, setIsOpenTestModal] = useState(false)
  const { isModalOpen: isSurveyModalOpen, closeModal: closeSurveyModal, openModal: openSurveyModal } = useModal()
  const chapterTitle = lang === "en" ? chapter.title_en : chapter.title_esp

  // At this moment, we have a problem with the render, because we must update the course.completed value after finishing the test
  // It understands 0 as falsy value for that reason !courseCompleted makes a wrong result
  return (
    <section className={index <= userAdvance || userAdvance === undefined ? "" : "disabled-chapter"}>
      {isOpenTestModal && <TestModal setIsOpenTestModal={setIsOpenTestModal} chapterId={chapter?.chapter_id} courseId={chapter?.course_id} setUserAdvance={setUserAdvance} userAdvance={userAdvance} />}
      {isVideoOpen && <VideoPlayerModal setIsVideoOpen={setIsVideoOpen} videoUrl={videoUrl} chapterTitle={chapterTitle} />}
      {isSurveyModalOpen && <SurveyModal closeModal={closeSurveyModal} isMandatory={true} courseId={chapter?.course_id} />}
      <div className="individual-chapter-container">
        <article className="individual-chapter-inside-container">
          <img src={downArrow} alt="down-arrow" className="img-down" onClick={() => setIsOpenChapter(!isOpenChapter)} />
          <span className="individual-chapter-title">{chapterTitle}</span>
        </article>
        <span className="duration-time">({chapter.duration} min)</span>
      </div>
      {isOpenChapter && <OpenedChapter chapter={chapter} setIsVideoOpen={setIsVideoOpen} setIsOpenTestModal={setIsOpenTestModal} openSurveyModal={openSurveyModal} setVideoUrl={setVideoUrl} />}
    </section>
  )
}


export default ChapterListItem