import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { RESET_PASSWORD } from "../../api/endpoints"
import { samePassordValidation } from "../../validators/samePasswordValidation"
import { ConfirmPasswordError } from "../../errors/CustomizeError"
import redcross from "../../images/redcross.svg"

const FormNewPassword = ({ email, error, setError }) => {
  const navigate = useNavigate()
  const [formInfo, setFormInfo] = useState({
    email,
    newPassword: undefined,
    confirmNewPassword: undefined,
  })
  const [isPassModified, setIsPassModified] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const handleFormInfo = (ev, type) => {
    if (type === "email") setFormInfo({ ...formInfo, [type]: ev.target.value.toLowerCase() })
    else setFormInfo({ ...formInfo, [type]: ev.target.value })
  }

  const submitHandle = async e => {
    e.preventDefault()
    try {
      samePassordValidation(formInfo.newPassword, formInfo.confirmNewPassword)
    } catch (e) {
      console.log("Error in password validation", e)
      if (e instanceof ConfirmPasswordError) setPasswordError(true)
      return
    }
    const url = RESET_PASSWORD.setNewPassword
    const body = JSON.stringify(formInfo)
    const fetchOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    }
    fetch(url, fetchOptions)
      .then(res => res.json())
      .then(res => {
        if (!res.isValid) {
          const errorName = res.data.error.name
          console.log("errorName", errorName)
          setError({ ...error, otherError: res.data.error })
        } else {
          // correct reset mail
          setIsPassModified(true)
          setTimeout(() => {
            navigate("/")
          }, 3300)
        }
      })
      .catch(e => {
        console.log("NETWORK ERROR - IMPOSSIBLE TO SEND REQUEST", e)
      })
  }
  return (<>
    <form onSubmit={submitHandle} className="sign-in-form-container">
      <label className="form-signin-label">
        <FormattedMessage id="email" />
      </label>
      <label className="email-field">{formInfo.email}</label>
      <label className="form-signin-label">
        <FormattedMessage id="password" />
      </label>
      <input type="password" className="form-signin-input" id="sign-in-password" onChange={ev => handleFormInfo(ev, "newPassword")} />
      <label className="form-signin-label">
        <FormattedMessage id="confirm-password" />
      </label>
      <input type="password" className="form-signin-input" id="sign-in-password" onChange={ev => handleFormInfo(ev, "confirmNewPassword")} />
      <button className="form-signin-button" type="submit" disabled={!formInfo.newPassword || !formInfo.confirmNewPassword}>
        <FormattedMessage id={"update"} />
      </button>
    </form>

    {isPassModified &&
      <div className="correct-login-container">
        <span className="login-correct"><FormattedMessage id="pass-updated" /></span>
      </div>
    }


    {passwordError &&
      <div className="error-msg-container">
        <img src={redcross} alt="Error" className="cross-img" />
        <span className="error-email"><FormattedMessage id="error-passwords" /></span>
      </div>
    }
  </>
  )
}

export default FormNewPassword