import { useState, useEffect } from "react"
import { COURSES_ENDPOINTS } from "../api/endpoints"

const useChapterForCourse = courseId => {
  const url = `${COURSES_ENDPOINTS.getChaptersByCourse}/${courseId}`
  const [chapters, setChapters] = useState(window.localStorage.getItem(`chapters-${courseId}`))
  const [error, setError] = useState(undefined)
  const [loaded, setLoaded] = useState(window.localStorage.getItem(`chapters-${courseId}`) !== undefined)

  useEffect(() => {
    if (!window.localStorage.getItem(`chapters-${courseId}`)) {
      setLoaded(false)
      fetch(url, {
        method: "get",
        headers: { authorization: window.localStorage.getItem("token") },
      })
        .then(res => res.json())
        .then(result => {
          if (result?.status !== 200 && result?.error) setError(result)
          else {
            setChapters(result)
            window.localStorage.setItem(`chapters-${courseId}`, JSON.stringify(result))
          }
        })
        .catch(e => setError(e))
        .finally(() => setLoaded(true))
    }
  }, [url, courseId])

  return { chapters, error, loaded }
}

export default useChapterForCourse
