import React from "react"
import { useGetData } from "../hooks/useGetData"
import { Navigate } from "react-router-dom"
import { COURSES_ENDPOINTS, USERS_ENDPOINTS } from "../api/endpoints"
import { FormattedMessage } from "react-intl"
import PersonalData from "../components/PersonalArea/PersonalData"
import NoCourse from "../components/PersonalArea/NoCourse"
import useModal from "../hooks/useModal"
import SurveyModal from "../components/Surveys/SurveyModal"
import UserCoursesCard from "../components/PersonalArea/UserCoursesCard"
import FullPageLoader from "../components/Loaders/FullPageLoader"
import "../styles/personalArea/personalArea.css"


const PersonalArea = () => {
  const { isModalOpen: isSurveyModal, closeModal: closeSurveyModal, openModal: openSurveyModal } = useModal()

  const email = window.localStorage.getItem("email")
  const localStorageUserCourses = window.localStorage.getItem(email) ? JSON.parse(window.localStorage.getItem(email)) : undefined

  const { result: user, loaded: loadedUserInfo } = useGetData(USERS_ENDPOINTS.getUser + `/${email}`)

  const { result: courses, loaded, error } = useGetData(COURSES_ENDPOINTS.getCourseByEmail + `?email=${email}`, localStorageUserCourses)

  if (!localStorageUserCourses && courses) {
    const userCourses = JSON.stringify(courses)
    window.localStorage.setItem(email, userCourses)
  }

  if (loadedUserInfo && !user) return <Navigate to="/" />
  return (
    <>
      {user && courses ? <section className="personal-area-container">
        {isSurveyModal && <SurveyModal closeModal={closeSurveyModal} isMandatory={false} />}
        <PersonalData user={user} openSurveyModal={openSurveyModal} />
        {loaded && !error && (
          <section className="personal-area-courses-container">
            <h4 className="your-courses-title"> <FormattedMessage id="your-courses" /></h4>
            <div className="user-courses-container">

              {courses?.map(c => <UserCoursesCard course={c} key={c.title_en} />)}
            </div>
            {courses?.length === 0 && <NoCourse />}
          </section>
        )}
      </section>
        : <FullPageLoader />}
    </>
  )
}

export default PersonalArea
