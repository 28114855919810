import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { hackedData } from '../../blockchain/hackedData'
import aave from '../../images/aave.png'
import multichain from '../../images/multichain.png'
import yearn from '../../images/yearnFinance.png'
import curve from '../../images/curve.png'
import bnb from '../../images/bnb.png'
import sushiswap from '../../images/sushiswap.png'
import euler from '../../images/euler.png'
import '../../styles/landing/defiHackeos.css'

const DefiHackeos = () => {
  const INTERVAL_TIME_MS = 3000
  const [itemToShow, setItemToShow] = useState(0)

  useEffect(() => {
    const t = setInterval(() => {
      setItemToShow(s => (s === hackedData.length - 1 ? 0 : s + 1))
    }, INTERVAL_TIME_MS)
    return () => clearInterval(t)
  }, [])


  const imageToUse = () => {
    if (hackedData[itemToShow].protocol.toUpperCase() === "AAVE") return aave
    else if (hackedData[itemToShow].protocol.toUpperCase() === "YEARN") return yearn
    else if (hackedData[itemToShow].protocol.toUpperCase() === "SUSHISWAP") return sushiswap
    else if (hackedData[itemToShow].protocol.toUpperCase() === "CURVE") return curve
    else if (hackedData[itemToShow].protocol.toUpperCase() === "EULER FINANCE") return euler
    else if (hackedData[itemToShow].protocol.toUpperCase() === "MULTICHAIN") return multichain
    else return bnb
  }
  return (
    <section className="defi-hackeos-section">
      <h3 className="title-hackeos"><FormattedMessage id="defi-hackeos" /></h3>
      <section className='automatic-slide-show'>
        <div className={`defi-hackeos-content`}>
        <article className="protocol-hack-info">
          <img src={imageToUse()} alt="protocol" className='hack-image' />
            <span className="protocol-name">{hackedData[itemToShow].protocol.toUpperCase()}</span>
        </article>
        <article className='stolen-info'>
            <span className="stolen-amount"> {hackedData[itemToShow].stolen} </span>
        </article>
        </div>
      </section>
      <p className='text-other-hacks'><FormattedMessage id="text-hacked-protocol-info" />
        <a className='text-other-hacks' href='https://chainsec.io/defi-hacks/' target='blank' rel='noreferrer'><FormattedMessage id="hacked-protocols" /></a></p>

    </section>
  )
}

export default DefiHackeos