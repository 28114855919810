import { Suspense, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { COURSES_ENDPOINTS } from "../api/endpoints";
import { FormattedMessage } from "react-intl";
import { useValidToken } from "../hooks/useValidToken";
import BuyModal from "../components/BuyCourse/BuyModal";
import SignModal from "../components/LogInLogOut/SignModal";
import useTitleDescriptionByLanguage from "../hooks/useTitleDescriptionByLanguage";
import useFetch from "fetch-suspense";
import AboutThisCourse from "../components/Courses/AboutThisCourse";
import IndividualCourseChapterSection from "../components/Courses/IndividualCourseChapterSection"
import FullPageLoader from "../components/Loaders/FullPageLoader";
import EnrollAccessButton from "../components/Courses/EnrollAccessButton";

import "../styles/individualCourse/individualCourse.css"

const IndividualCourse = () => {
  const { id } = useParams();
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const [isOpenSignModal, setIsOpenSignModal] = useState(false);
  const { isValidToken } = useValidToken();
  const [alreadyHaveAccount, setAlreadyHaveAccount] = useState(isValidToken);
  const course = JSON.parse(useFetch(`${COURSES_ENDPOINTS.getCourse}/${id}`));
  const { title, description } = useTitleDescriptionByLanguage(course);

  const enrollNowHandler = () => {
    if (isValidToken) {
      setIsBuyModalOpen(true);
    } else {
      setIsOpenSignModal(true)
    }
  }

  if (!course) return <Navigate to="/courses" /> // Avoid user can enter in courses/:id where id is not defined
  return (
    <main className="individual-course-main">
      {isBuyModalOpen && isValidToken && (<BuyModal setIsBuyModalOpen={setIsBuyModalOpen} course={course} />)}
      {isOpenSignModal && !isValidToken && (<SignModal setIsOpenSignModal={setIsOpenSignModal} alreadyHaveAccount={alreadyHaveAccount} setAlreadyHaveAccount={setAlreadyHaveAccount} />)}
      <header className="header-course-info">
        <h3 className="title-course-page">{title}</h3>
        <EnrollAccessButton isValidToken={isValidToken} enrollNowHandler={enrollNowHandler} />
      </header>

      <section className="individual-course-container">
        <h2 className="title-program"><FormattedMessage id="about-this-course" /></h2>
        <AboutThisCourse course={course} />

        <h2 className="title-program"><FormattedMessage id="what-learn" /></h2>
        <p className="short-description-p">{description}</p>

        <IndividualCourseChapterSection id={id} />
      </section>
    </main>
  );
};


const WrappedIndividualCourse = () => {
  return (
    <Suspense fallback={<FullPageLoader />} >
      <IndividualCourse />
    </Suspense>
  )
}
export default WrappedIndividualCourse;
