import { useState } from "react";
import MetaMaskConnect from "../components/BuyCourse/MetaMaskConnect"
import { NETWORK_DATA } from "../blockchain/networks";
import { FormattedMessage } from "react-intl"
import { getNFTData } from "../blockchain/coursesNFTs";
import { useParams } from "react-router-dom";
import Loading from '../components/Loading'

import '../styles/mintNft/mintNft.css'
const ethers = require("ethers");

const MintNft = () => {
  const [web3Provider, setWeb3Provider] = useState(undefined);
  const [userWalletInfo, setUserWalletInfo] = useState({ address: undefined, balance: undefined });
  const [loading, setIsLoading] = useState(undefined)
  const [nftMintedUrl, setNftMintedUrl] = useState(undefined)
  const [error, setError] = useState(undefined)
  const { courseId } = useParams();

  const NFTData = getNFTData(courseId)

  const mintHandler = async () => {
    const NFT = new ethers.Contract(
      NFTData.contractAddress,
      NFTData.contractABI
    );
    const signer = web3Provider.getSigner();
    const NFT_CONTRACT = NFT.connect(signer);
    setIsLoading(true)
    setError(false)
    try {
      const latestBlockNumber = await web3Provider.getBlockNumber();
      const txRes = await NFT_CONTRACT.mintNFT({
        gasLimit: 4500000,
      });

      await txRes.wait();

      const eventFilter = NFT_CONTRACT.filters.NFTMinted();
      const events = await NFT_CONTRACT.queryFilter(eventFilter, latestBlockNumber);

      if (events.length > 0) {
        const tokenId = events[0]?.args?.tokenId?.toString();
        const constructedURL = `${NETWORK_DATA.openseaUrl}${NFTData.contractAddress}/${tokenId}`;
        setNftMintedUrl(constructedURL)
      }

    } catch (error) {
      console.error("Error minting nft:", error.message);
      setError(error)
      setNftMintedUrl(undefined)
    }
    finally {
      setIsLoading(false)
    }
  }
  return (
    <main className="main-mint-nft">
      <h2 className="mint-nft-title"><FormattedMessage id="mint-nft-for-course" /></h2>
      <p className="text-nft-benefits"><FormattedMessage id="text-nft-benefits" /></p>
      <img src={NFTData.NFTVideo} alt="member nft" className='gif-img' />
      <>
        {!userWalletInfo?.address ? (
          <MetaMaskConnect
            setUserWalletInfo={setUserWalletInfo}
            setWeb3Provider={setWeb3Provider}
            isMintNft={true}
          />
        ) : (
          <button className="form-signin-button" onClick={mintHandler}>Mint course Nft!</button>
        )}
      </>

      {nftMintedUrl && !loading && (
        <a href={nftMintedUrl} target="_blank" rel="noopener noreferrer" className="view-nft-opensea">
          <FormattedMessage id="view-minted-nft" />
        </a>
      )}
      {!nftMintedUrl && loading && <Loading />}
      {error && <span className="error-message-sign"><FormattedMessage id="error-minting-try-later" /></span>}
    </main>
  )
}

export default MintNft