import { FormattedMessage } from 'react-intl'
import '../../styles/landing/salaryAnimation.css'

const SalaryAnimation = () => {
  return (
    <div className="salary-marquee-container">
      <p className="salary-marquee-text"> <span className="star-icon">🌟</span> <FormattedMessage id="average-salary" />  <FormattedMessage id="junior-salary" /> | <FormattedMessage id="mid-salary" /> | <FormattedMessage id="senior-salary" /><span className="star-icon">🌟</span> </p>
    </div>
  )
}

export default SalaryAnimation