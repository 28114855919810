// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-survey {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 99999;
}

.modal-survey-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  height: 480px;
  border: 3px solid rgb(129, 129, 129);
  border-radius: 6px;
  background-color: black;
  padding: 40px;
}

.header-survey-modal {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.btn-header-survey-modal {
  padding: 5px 8px;
  border: 2px solid grey;
  border-radius: 5px;
  background: transparent;
  font-family: var(--font-family);
  font-size: 1rem;
  color: grey;
  cursor: pointer;
}

.btn-header-survey-modal:hover {
  font-weight: bold;
}

@media (max-width: 600px) {
  .modal-survey-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: var(--mobile-max-container-width);
  height: auto;
  background-color: black;
  padding: 30px 5px;
}
}`, "",{"version":3,"sources":["webpack://./src/styles/surveyModal.css"],"names":[],"mappings":"AACA;EACE,eAAe;EACf,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,WAAW;EACX,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,oCAAoC;EACpC,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,oCAAoC;EACpC,kBAAkB;EAClB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;EAClB,uBAAuB;EACvB,+BAA+B;EAC/B,eAAe;EACf,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;EACA,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,wCAAwC;EACxC,YAAY;EACZ,uBAAuB;EACvB,iBAAiB;AACnB;AACA","sourcesContent":["@import url(\"global.css\");\n.modal-survey {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.9);\n  z-index: 99999;\n}\n\n.modal-survey-content {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  width: 500px;\n  height: 480px;\n  border: 3px solid rgb(129, 129, 129);\n  border-radius: 6px;\n  background-color: black;\n  padding: 40px;\n}\n\n.header-survey-modal {\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 20px;\n}\n.btn-header-survey-modal {\n  padding: 5px 8px;\n  border: 2px solid grey;\n  border-radius: 5px;\n  background: transparent;\n  font-family: var(--font-family);\n  font-size: 1rem;\n  color: grey;\n  cursor: pointer;\n}\n\n.btn-header-survey-modal:hover {\n  font-weight: bold;\n}\n\n@media (max-width: 600px) {\n  .modal-survey-content {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  width: var(--mobile-max-container-width);\n  height: auto;\n  background-color: black;\n  padding: 30px 5px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
