
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import '../../styles/signInOut/signOutModal.css'

const SignOutModal = ({ setIsSignOutModalOpen }) => {
  const navigate = useNavigate()
  const goBackHandler = () => setIsSignOutModalOpen(false)
  const signOutHandler = () => {
    window.localStorage.clear()
    setIsSignOutModalOpen(false)
    navigate("/")

  }
  return (
    <div className="modal-sign-out">
      <div className="modal-content-sign-out">
        <h4 className="title-sign-out"><FormattedMessage id="sure-to-sign-out" /></h4>
        <section className="btn-sign-out-container">
          <button onClick={goBackHandler} className="go-back-button"> <FormattedMessage id="go-back" /></button>
          <button onClick={signOutHandler} className="sign-out-button"> <FormattedMessage id="sign-out" /></button>
        </section>
      </div>
    </div>
  )
}

export default SignOutModal