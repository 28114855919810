import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import TokenSelector from "./TokenSelector";
import MetaMaskConnect from "./MetaMaskConnect";
import { TOKENS_FOR_PAY_ADDRESS, PAYMENT_CONTRACT_ADDRESS } from "../../blockchain/addresses";
import stablecoinAbi from "../../blockchain/ABIs/stablecoinAbi.json";
import paymentSystemAbi from "../../blockchain/ABIs/PaymentSystem.json";
import checkImg from "../../images/check.svg";
import Loading from "../Loading";
import addBuyCourseToDB from "./addBuyCourseToDB";

const ethers = require("ethers");

const CryptoPayment = ({ course, isPayWitCrypto }) => {
  const [web3Provider, setWeb3Provider] = useState(undefined);
  const [approvalState, setApprovalState] = useState({ done: false, loading: false, error: false, });
  const [paymentState, setPaymentState] = useState({ done: false, loading: false, error: false });
  const [userWalletInfo, setUserWalletInfo] = useState({ address: undefined, balance: undefined });
  const [token, setToken] = useState(null);

  const approveHandler = async () => {
    const tokenContractInstance = new ethers.Contract(TOKENS_FOR_PAY_ADDRESS[token?.value], stablecoinAbi);
    const signer = web3Provider.getSigner();
    const tokenContract = tokenContractInstance.connect(signer);
    tokenContract
      .approve(PAYMENT_CONTRACT_ADDRESS, 100000000)
      .then((tx) => {
        setApprovalState({ ...approvalState, loading: true });
      })
      .catch((e) => {
        console.log(`%c${e.message}`, "color: red; background: black; font-size: 20px");
        setApprovalState({ ...approvalState, error: true });
      });

    tokenContract.once("Approval", (owner, spender, amount) =>
      setApprovalState({ done: true, loading: false, error: false })
    );
  };

  const buyCourseHandler = async () => {
    const userMail = window.localStorage.getItem("email")
    setPaymentState({ ...paymentState, loading: true });
    const STABLECOIN = new ethers.Contract(
      PAYMENT_CONTRACT_ADDRESS,
      paymentSystemAbi
    );
    const signer = web3Provider.getSigner();
    const paySystemContract = STABLECOIN.connect(signer);

    try {
      const txRes = await paySystemContract.buyCourse(course.course_id, TOKENS_FOR_PAY_ADDRESS[token?.value]);

      await txRes.wait();
      const eventFilter = paySystemContract.filters.BuyCourse();
      const events = await paySystemContract.queryFilter(eventFilter);

      if (events.length > 0) {
        await addBuyCourseToDB(course)
        window.localStorage.removeItem(userMail) // Remove localtorage info about courses previously bought by the user.
        setPaymentState({ done: true, loading: false, error: false });
      }
    } catch (error) {
      console.error("Error:", error);
      setPaymentState({ done: false, loading: false, error: true });
    }
  };


  return (
    <>
      {isPayWitCrypto && !userWalletInfo?.address && (<MetaMaskConnect setUserWalletInfo={setUserWalletInfo} setWeb3Provider={setWeb3Provider} />)}
      {userWalletInfo?.address && (
        <section className="buy-btns-container">
          {!approvalState.done && (
            <>
              <TokenSelector token={token} setToken={setToken} />
              <button className="buy-crypto-btn" onClick={approveHandler} disabled={!token}>  <FormattedMessage id="approve-token" /></button>
            </>
          )}
          {approvalState.done && !paymentState.done && (
            <button className="buy-crypto-btn" onClick={buyCourseHandler}> <FormattedMessage id="accept-payment" /></button>
          )}
        </section>
      )}

      {paymentState.done && (
        <Link to={"/personal-area/courses/" + course.course_id} className="payment-done" >
          <img src={checkImg} alt="done-check" />
          <FormattedMessage id="payment-done" />
        </Link>
      )}
      {(approvalState.loading || paymentState.loading) && (<Loading text="Waiting for transaction..." />)}
    </>
  );
};

export default CryptoPayment;
