import MemberNFTAbi from './ABIs/MemberNFT.json'
import MemberNFTVideo from './NFTVideos/MemberNFT.gif'
import FreeCourseNFTAbi from './ABIs/FreeCourseNFT.json'
import FreeCourseNFTVideo from './NFTVideos/FreeCourseNFT.gif'

const FREECOURSENFT = {
    mumbai: {
        contractAddress: '',
        contractABI: FreeCourseNFTAbi,
        NFTVideo: FreeCourseNFTVideo
    },
    arbitrum: {
        contractAddress: '0x7da344ed193e40Fe1B33b46d1Bcd52e66BCd545C',
        contractABI: FreeCourseNFTAbi,
        NFTVideo: FreeCourseNFTVideo
    },
  }

const MEMBERNFT = {
    mumbai: {
        contractAddress: '0x5C6e9690e43E619Cda59e0d0Cd6664610Cd008a0',
        contractABI: MemberNFTAbi,
        NFTVideo: MemberNFTVideo
    },
    arbitrum: {
        contractAddress: '0xc70d142C0E73B94827EC52e177F09361a84f96fc',
        contractABI: MemberNFTAbi,
        NFTVideo: MemberNFTVideo
    },
}



const ENV = process.env.REACT_APP_DEVELOP
export const getNFTData = (courseId) => {
    const dataMap = {
        0: ENV === "DEVELOP" ? MEMBERNFT.mumbai : MEMBERNFT.arbitrum,
        1: ENV === "DEVELOP" ? FREECOURSENFT.mumbai : FREECOURSENFT.arbitrum,
    };
    return dataMap[courseId] || {};
};

