export const NETWORKS = {
  mumbai: {
    chainId: "0x13881",
    chainName: "Mumbai",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.ankr.com/polygon_mumbai"], // Mumbai RPC URL
    blockExplorerUrls: ["https://mumbai.polygonscan.com"], // Mumbai Block Explorer URL
  },
  arbitrum: {
    chainId: "0xa4b1",
    chainName: "Arbitrum One",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://arb1.arbitrum.io/rpc"], // Mumbai RPC URL
    blockExplorerUrls: ["https://arbiscan.io"], // Mumbai Block Explorer URL
  },
}

const OPENSEA_URL = {
  mumbai: "https://testnets.opensea.io/es/assets/mumbai/",
  arbitrum: "https://opensea.io/es/assets/arbitrum/",
}

export const ENV = process.env.REACT_APP_DEVELOP
export const NETWORK_DATA = {
  openseaUrl: ENV === "DEVELOP" ? OPENSEA_URL.mumbai : OPENSEA_URL.arbitrum,
}
