// UserProgress is info stored in redux
// course contains the info in bd related to userProgress for specific course
const getCurrentCourseProgress = ({ userProgress, course }) => {
  const courseCompleted = course?.completed ? course.completed : 0
  const progressForCurrentCourse = userProgress?.filter(courses => +courses?.courseId === +course.course_bought_id).map(c => c.completed)
  const currentProgress = progressForCurrentCourse.length > 0 ? Math.max(...progressForCurrentCourse) : undefined
  const progress = currentProgress ?? courseCompleted
  return progress
}

export default getCurrentCourseProgress

export const getUserAdvance = (reduxProgress, apiProgress) => {
  if (+reduxProgress === 0 && +apiProgress === 0) return 0
  if (+apiProgress > +reduxProgress) return apiProgress
  else if (+reduxProgress >= +apiProgress) return reduxProgress
}
