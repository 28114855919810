
import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { RESET_PASSWORD } from "../../api/endpoints"
import Loading from "../Loading"
const FormVerifyCode = ({ setIsCodeVerified, setError, email }) => {
  const [code, setCode] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const handleCodeInfo = (ev) => { setCode(ev.target.value) }

  const verifyCodeHandle = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const url = RESET_PASSWORD.verifyCode
    const fetchOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ code, email })
    }
    fetch(url, fetchOptions)
      .then(res => res.json())
      .then(res => {
        if (res.isVerified !== true) {
          setError(true)
        } else {
          setError(undefined)
          setTimeout(() => { setIsCodeVerified(true) }, 2500)
        }
      })
      .catch(e => {
        console.error("Network error", e)
        setError(true)
      })
      .finally(() => setIsLoading(false))
  }
  return (
    <>
      <form onSubmit={verifyCodeHandle} className="sign-in-form-container">
        <label className="form-signin-label"><FormattedMessage id="recover-code" /></label>
        <input type="number" className="form-signin-input" id="sign-in-email" placeholder="Code" value={code} onChange={handleCodeInfo} />
        <button className="form-signin-button" type="submit" disabled={!email}><FormattedMessage id="verify-code" /></button>
      </form>
      <p className="code-sent-to-email"><FormattedMessage id="introduce-the-code" /></p>
      {isLoading && <Loading />}
    </>
  )
}

export default FormVerifyCode