// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stripe-payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pay-now-btn {
  width: 100%;
  margin-top: 20px;
  padding: 8px 6px;
  text-align: center;
  border: none;
  border-radius: 4px;
  font-family: var(--font-family);
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  font-weight: bold;
  background-color: var(--cta-color);
  color: white;
  cursor: pointer;
}

.error-stripe-message {
  display: inline-flex;
  color: red;
  font-size: 1rem;
  font-family: var(--font-family);
  font-weight: bold;
  margin-top: 20px;
}

.width-100 {
  margin-top: 20px;
  width: 500px;
  box-sizing: border-box;
}

#payment-form {
  width: 500px;
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/styles/individualCourse/stripePayment.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,+BAA+B;EAC/B,iBAAiB;EACjB,qBAAqB;EACrB,iBAAiB;EACjB,kCAAkC;EAClC,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,UAAU;EACV,eAAe;EACf,+BAA+B;EAC/B,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,sBAAsB;AACxB","sourcesContent":[".stripe-payment-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  margin-top: 20px;\n  margin-bottom: 20px;\n}\n\n.pay-now-btn {\n  width: 100%;\n  margin-top: 20px;\n  padding: 8px 6px;\n  text-align: center;\n  border: none;\n  border-radius: 4px;\n  font-family: var(--font-family);\n  font-size: 1.1rem;\n  letter-spacing: 0.5px;\n  font-weight: bold;\n  background-color: var(--cta-color);\n  color: white;\n  cursor: pointer;\n}\n\n.error-stripe-message {\n  display: inline-flex;\n  color: red;\n  font-size: 1rem;\n  font-family: var(--font-family);\n  font-weight: bold;\n  margin-top: 20px;\n}\n\n.width-100 {\n  margin-top: 20px;\n  width: 500px;\n  box-sizing: border-box;\n}\n\n#payment-form {\n  width: 500px;\n  box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
