import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./languageSlice";
import progressReducer from "./progressCourseSlice";

// the reducer is the name which defines the value used in selector
const store = configureStore({
  reducer: {
    language: languageReducer,
    progress: progressReducer
  },
});

export default store;