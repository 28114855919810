// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forget-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 60px;
  border-radius: 5px;
  min-height: 100vh;
}

.title-forgot-password {
  color: white;
  font-size: 1.7rem;
  letter-spacing: 3px;
  font-family: var(--font-family);
}

.email-field {
  color: rgb(255, 255, 135);
  font-size: 1rem;
  font-weight: 500;
}

button:disabled {
  opacity: 0.5;
}

.error-email {
  color: rgb(255, 65, 65);
  letter-spacing: 1px;
  padding: 5px;
}

.code-sent-to-email {
  text-align: center;
  font-size: 1rem;
  color: grey;
  letter-spacing: 1px;
  font-style: italic;
}

@media (max-width: 600px) {
  .title-forgot-password {
    font-size: 1.5rem;
    text-align: center;
    letter-spacing: 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/forgotPassword.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE;IACE,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;EACrB;AACF","sourcesContent":["@import url(\"global.css\");\n\n.forget-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  margin-top: 60px;\n  border-radius: 5px;\n  min-height: 100vh;\n}\n\n.title-forgot-password {\n  color: white;\n  font-size: 1.7rem;\n  letter-spacing: 3px;\n  font-family: var(--font-family);\n}\n\n.email-field {\n  color: rgb(255, 255, 135);\n  font-size: 1rem;\n  font-weight: 500;\n}\n\nbutton:disabled {\n  opacity: 0.5;\n}\n\n.error-email {\n  color: rgb(255, 65, 65);\n  letter-spacing: 1px;\n  padding: 5px;\n}\n\n.code-sent-to-email {\n  text-align: center;\n  font-size: 1rem;\n  color: grey;\n  letter-spacing: 1px;\n  font-style: italic;\n}\n\n@media (max-width: 600px) {\n  .title-forgot-password {\n    font-size: 1.5rem;\n    text-align: center;\n    letter-spacing: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
