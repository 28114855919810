import { createSlice } from "@reduxjs/toolkit"

const progressCourseSlice = createSlice({
  name: "progressCourse",
  initialState: [],
  reducers: {
    updateProgress: (state, action) => [...state, { courseId: action.payload.courseId, completed: action.payload.completed }],
  },
})

// export default store
const { actions, reducer } = progressCourseSlice
export const { updateProgress } = actions
export default reducer
