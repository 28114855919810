import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { COURSES_ENDPOINTS } from "../../api/endpoints"
import { Link, useNavigate } from "react-router-dom"
import { useGetData } from "../../hooks/useGetData";
import { useState, useEffect } from 'react'

import { getUserAdvance } from "../../helpers/progressHelper";
import "../../styles/courses/coursesInfo.css"


const UserCoursesCard = ({ course: c }) => {
  const lang = useSelector(s => s.language)
  const userProgress = useSelector(s => s.progress)
  const navigate = useNavigate()
  const descriptionToUse = lang === "en" ? c.description_en : c.description_esp
  const title = lang === "en" ? c.title_en : c.title_esp
  const reducedDescription = descriptionToUse.slice(0, 100) + "..."
  const url = `${COURSES_ENDPOINTS.getCourseCompletedByEmail}?email=${window.localStorage.getItem("email")}&courseid=${c.course_id}`
  const { result: courseBought, loaded } = useGetData(url, false)
  const courseCompleted = courseBought?.completed
  const progressForThisCourse = userProgress?.filter(c => +c?.courseId === +c.course_id)
  const maxProgress = progressForThisCourse.length > 0 ? Math.max(...progressForThisCourse?.map(c => c?.completed)) : 0
  const [userAdvance, setUserAdvance] = useState(0)
  useEffect(() => {
    const advance = getUserAdvance(maxProgress, courseCompleted);
    setUserAdvance(advance);
  }, [maxProgress, courseCompleted]);



  const mintHandler = async (e) => {
    console.log("Mint Handler")
    e.preventDefault();
    navigate("/mint-nft/" + c?.course_id)
  }

  if (!loaded) return <></>
  if (loaded) {
    return (
      <Link key={c.title_en} className="course-card-container" to={`/personal-area/courses/${c?.course_id}`} >
      <div className="title-description-container">
        <section className="title-description">
          <h4 className="courses-title">{title}</h4>
          <p className="courses-description">{reducedDescription}</p>
        </section>
      </div>
      <h5 className={userAdvance !== c?.total_chapters ? "completed-text" : "completed-text done"}>
        {((userAdvance / c?.total_chapters) * 100).toFixed(2)} % <FormattedMessage id="completed" />
      </h5>
      {userAdvance === c?.total_chapters && <button className="mint-button" onClick={(e) => mintHandler(e)}><FormattedMessage id="mint-course-nft" /></button>}
    </Link>
    )
    
  }
}

export default UserCoursesCard