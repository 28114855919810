import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { RESET_PASSWORD } from "../../api/endpoints"
import redcross from "../../images/redcross.svg"
import Loading from "../Loading"

const ErrorMessage = ({ message }) => (
  <div className="error-msg-container">
    <img src={redcross} alt="Error" className="cross-img" />
    <span className="error-email"><FormattedMessage id={message} /></span>
  </div>
);

const SuccessMessage = ({ message }) => (
  <div className="correct-login-container">
    <span className="login-correct"><FormattedMessage id={message} /></span>
  </div>
);

const FormRequestCode = ({ email, setEmail, setError, sent, setSent }) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleFormInfo = (ev) => {
    setError(false)
    setEmail(ev.target.value)
  }
  const [sendError, setSendError] = useState(false)
  const submitHandle = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const url = RESET_PASSWORD.orderResetPassword
    const fetchOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    }
    fetch(url, fetchOptions)
      .then(res => res.json())
      .then(res => {
        if (res.isValid !== true) {
          setSent(false)
          setError(true)
        } else {
          setSent(true)
          setError(false)
          setSendError(false)
        }
      })
      .catch(e => {
        console.log("Network error", e)
        setError(e)
      })
      .finally(() => setIsLoading(false))
  }
  return (
    <>
      <form onSubmit={submitHandle} className="sign-in-form-container">
        <label className="form-signin-label"><FormattedMessage id="email" /></label>
        <input type="email" className="form-signin-input" id="sign-in-email" onChange={handleFormInfo} />
        <button className="form-signin-button" type="submit" disabled={!email || isLoading}><FormattedMessage id="send-email-to-recover" /></button>
      </form>
      {isLoading && <Loading />}
      {sendError && <ErrorMessage message="send-email-error" />}
      {sent && <SuccessMessage message="email-sent" />}
    </>
  )
}


export default FormRequestCode