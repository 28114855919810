import React from "react";
import { ENV, NETWORKS } from "../../blockchain/networks";
import { FormattedMessage } from "react-intl";
import arbitrumImg from '../../images/arb.webp'
import '../../styles/metamaskConnect.css'
const ethers = require("ethers");

const MetaMaskConnect = ({ setUserWalletInfo, setWeb3Provider, isMintNft }) => {
  const buttonHandler = () => {
    if (window.ethereum) {
      const chainId = window.ethereum.chainId
      const correctNetwork = ENV === "DEVELOP" ? NETWORKS.mumbai : NETWORKS.arbitrum
      if (chainId !== correctNetwork.chainId) {
        window.ethereum.request({ method: 'wallet_addEthereumChain', params: [correctNetwork], })
      }
      window.ethereum.request({ method: 'eth_requestAccounts' })
        .then(accounts => accountChangeHandler(accounts[0]))
        .catch(error => console.error("Error getting metamask accounts", error.message))
    }
  };

  const accountChangeHandler = (address) => {
    window.ethereum.request({ method: "eth_getBalance", params: [address, "latest"], })
      .then((balance) => {
        setUserWalletInfo({
          address: address,
          balance: ethers.utils.formatEther(balance),
        })
      })
      .catch(error => console.error("Error getting metamask balances", error.message))
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    setWeb3Provider(provider)
  }

  return (
    <div className="metamask-connect-container">
      <button onClick={buttonHandler} className="connect-wallet"> <FormattedMessage id="connect-wallet" /></button>
      {isMintNft ?
        <div className="arb-network-container-mint-nft">
          <img src={arbitrumImg} alt="arbitrum network logo" className="arb-img" />
          <span className="text-only-arb"><FormattedMessage id="nft-minting" /> <b>Arbitrum Network</b></span>
        </div>
        : <div className="arb-network-container">
          <img src={arbitrumImg} alt="arbitrum network logo" className="arb-img" />
          <span className="text-only-arb"><FormattedMessage id="available-crypto-payments" /> <b>Arbitrum Network</b></span>
        </div>
      }
    </div>
  );
};

export default MetaMaskConnect;
