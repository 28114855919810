
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import useTitleDescriptionByLanguage from "../../hooks/useTitleDescriptionByLanguage"
import "../../styles/courses/coursesInfo.css"

const CoursesInfo = ({ course }) => {
  const { title, description } = useTitleDescriptionByLanguage(course);

  const reducedDescription = description && description?.slice(0, 130) + "..."

  return (
    <Link className="course-card-container" to={`/courses/${course?.course_id}`}>
      <div className="title-description-container">
        <section className="title-description">
          <h4 className="courses-title">{title}</h4>
          <p className="courses-description">{reducedDescription}</p>
        </section>
        <section className="buy-course-container">
          <p className="price">{course?.price} €</p>
          <Link className="btn-buy" to={`/courses/${course?.course_id}`}><FormattedMessage id="buy-course" /></Link>
        </section>
      </div>
    </Link>
  )
};

export default CoursesInfo;
