import { USERSPOINTS_ENDPOINTS } from "../api/endpoints";

// Function to update the user points for specific field
// 1º Get previous userPoints || 2º Update the points for specific field with oldPoints + newPoints

const addUserPoints = async (field, newPoints) => {
  const email = window.localStorage.getItem("email")
  const token = window.localStorage.getItem("token")
  const res = await fetch(`${USERSPOINTS_ENDPOINTS.getAllPoints}/${email}`, { method: "GET", headers: { authorization: token } })

  const userPoints = await res.json() // Is this object { user_id: userId, buycourse: 0, form: 0, completedcourse: 0 }
  const currentFieldPoints = userPoints[field]

  const totalNewPoints = currentFieldPoints !== undefined ? currentFieldPoints + newPoints : newPoints

  await fetch(USERSPOINTS_ENDPOINTS.modifyPoints, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
    body: JSON.stringify({
      userId: userPoints?.user_id,
      field: field,
      points: totalNewPoints,
    }),
  })
};

export default addUserPoints;
