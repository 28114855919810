
import OptionalSurveyForm from "./OptionalSurveyForm";
import { surveyOptionalEN, surveyOptionalESP } from "../../helpers/surveys/optionalSurveys/surveysOptionalESPAndEN";
import { useItemByLanguage } from "../../hooks/useTitleDescriptionByLanguage";
import MandatorySurveyForm from "./MandatorySurveyForm";
import { FormattedMessage } from "react-intl";
import "../../styles/surveyModal.css";

const SurveyModal = ({ closeModal, isMandatory, courseId }) => {
  const optionalSurvey = useItemByLanguage(surveyOptionalEN, surveyOptionalESP)
  return (
    <div className="modal-survey">
      <div className="modal-survey-content">
        <header className="header-survey-modal">
          <button className="btn-header-survey-modal" onClick={closeModal}><FormattedMessage id="x-close" /></button>
        </header>
        {!isMandatory
          ? <OptionalSurveyForm optionalSurvey={optionalSurvey} />
          : <MandatorySurveyForm courseId={courseId} />
        }

      </div>
    </div>
  );
};

export default SurveyModal;
