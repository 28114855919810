import { useState } from "react";
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import { useValidToken } from "../../hooks/useValidToken";
import SignModal from "../../components/LogInLogOut/SignModal";
import MemberShipGif from "../../blockchain/NFTVideos/MemberNFT.gif"
import FreeCourseGif from "../../blockchain/NFTVideos/FreeCourseNFT.gif"
import '../../styles/landing/nftLanding.css'

const NFTsLanding = () => {
  const navigate = useNavigate()
  const { isValidToken } = useValidToken();
  const [isOpenSignModal, setIsOpenSignModal] = useState(false);
  const [alreadyHaveAccount, setAlreadyHaveAccount] = useState(isValidToken);

  const mintMembershipHandle = () => {
    if (isValidToken) {
      navigate("/mint-nft/0")
    } else {
      setIsOpenSignModal(true)
    }
  }

  const mintFreeHandle = () => {
    if (isValidToken) {
      navigate("/courses/1")
    } else {
      setIsOpenSignModal(true)
    }
  }

  return (
    <section className="landing-membershipNFT">
      {isOpenSignModal && !isValidToken && (<SignModal setIsOpenSignModal={setIsOpenSignModal} alreadyHaveAccount={alreadyHaveAccount} setAlreadyHaveAccount={setAlreadyHaveAccount} />)}
      <div className="landing-nft-container">
        <h4 className="nft-title"><FormattedMessage id="membership-nft" /></h4>
        <img src={MemberShipGif} alt="member nft" className='gif-img' />
        <span className="nfts-landing-text"><FormattedMessage id="membership-nft-landing-text" /></span>
        <button onClick={mintMembershipHandle} className="free-nft-member"> <FormattedMessage id="free-mint" /></button>
      </div>
      <div className="landing-nft-container">
        <h4 className="nft-title"><FormattedMessage id="freecourse-nft" /></h4>
        <img src={FreeCourseGif} alt="free course nft" className='gif-img' />
        <span className="nfts-landing-text"><FormattedMessage id="freecourse-nft-landing-text" /></span>
        <button onClick={mintFreeHandle} className="free-nft-member"> <FormattedMessage id="free-mint" /></button>
      </div>
    </section>
  )
}

export default NFTsLanding