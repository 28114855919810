import fundingRate from './fundingRate.jpg';
import chain from './chain.jpg';
import crosschain from './crosschain.jpg';
import reentrancy from './reentrancyAttack.jpg';
import defiPost from './defiPost.jpg'
import blockchainPost from './blockchainPost.jpg'

export const IMAGES = {
  fundingRate,
  chain,
  crosschain,
  reentrancy,
  defiPost,
  blockchainPost
}