import { FormattedMessage } from "react-intl"
import '../../styles/landing/becomeEngineer.css'

const BecomeEngineer = () => {
  return (
    <section className='become-container'>
      <h1 className='become-title'><FormattedMessage id="become-a" /><b className="main-words"><FormattedMessage id="blockchain-engineer" /></b></h1>
      <p className='become-p'><FormattedMessage id="become-engineer-text" /></p>
    </section>
  )
}

export default BecomeEngineer