import { useSelector } from 'react-redux'
import videoIcon from '../../images/video.svg'
import testIcon from '../../images/test.svg'
import surveyIcon from '../../images/survey.svg'
import { FormattedMessage } from "react-intl"
import '../../styles/personalAreaCourses/openedChapter.css'

// This is the content shown when a chapter is open
const OpenedChapter = ({ chapter, setIsVideoOpen, setIsOpenTestModal, openSurveyModal, setVideoUrl }) => {
  const lang = useSelector((s) => s.language)
  const openChapterHandler = () => {
    setVideoUrl(lang === "en" ? chapter.videos_en : chapter.videos_esp)
    setIsVideoOpen(true)
  }

  return (
    <section className="each-chapter-info-container">
      <p className="chapter-inside-description">{lang === "en" ? chapter.description_en : chapter.description_esp} </p>
      <div className='videos-list'>
        {!chapter?.isSurvey &&
          <article className="chapter-test-container" onClick={openChapterHandler}>
            <img src={videoIcon} alt="video-player" className="img-video-title" />
            <span className="video-test-text"> <FormattedMessage id="chapter-videos" /></span>
          </article>}

        {!chapter?.isSurvey &&
          <article className="chapter-test-container" onClick={() => setIsOpenTestModal(true)}>
            <img src={testIcon} alt="test-icon" className="img-video-title" />
            <span className="video-test-text" > <FormattedMessage id="chapter-test" /></span>
          </article>
        }
        {chapter?.isSurvey &&
          <article className="chapter-test-container" onClick={() => openSurveyModal(true)}>
            <img src={surveyIcon} alt="survey-icon" className="img-video-title" />
            <span className="video-test-text" > <FormattedMessage id="survey" /></span>
          </article>}
      </div>
    </section>
  )
}


export default OpenedChapter