import React, { lazy, Suspense } from "react"
import { useParams } from "react-router-dom"
import { POST_SUMMARY } from "../blogContent/postSummary"
import Loading from "../components/Loading"


// Lazy loading permit not load the components until they are render for first time. It is a code-splitting technique which improves app performance
// Lazy always must work with Suspense
const LazyPost1 = lazy(() => import('../components/Posts/LazyPost1'))
const LazyPost2 = lazy(() => import('../components/Posts/LazyPost2'))
const LazyPost3 = lazy(() => import('../components/Posts/LazyPost3'))
const LazyPost4 = lazy(() => import('../components/Posts/LazyPost4'))
// const LazyPost5 = lazy(() => import('../components/Posts/LazyPost5'))
// const LazyPost6 = lazy(() => import('../components/Posts/LazyPost6'))

const BlogIndividual = () => {
  const { title } = useParams()

  return (
    <Suspense fallback={<Loading isBig={true} />}  >
      {title === POST_SUMMARY[0].filename && <LazyPost1 />}
      {title === POST_SUMMARY[1].filename && <LazyPost2 />}
      {title === POST_SUMMARY[2].filename && <LazyPost3 />}
      {title === POST_SUMMARY[3].filename && <LazyPost4 />}
      {/* {title === POST_SUMMARY[4].filename && <LazyPost5 />}
      {title === POST_SUMMARY[5].filename && <LazyPost6 />} */}
    </Suspense>
  )
}


export default BlogIndividual

