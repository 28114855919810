import { useSelector } from "react-redux"

const useTitleDescriptionByLanguage = (course) => {
  const lang = useSelector((s) => s.language)
  const title = lang === "en" ? course?.title_en : course?.title_esp
  const description = lang === "en" ? course?.description_en : course?.description_esp
  return { title, description }
};

export default useTitleDescriptionByLanguage;

export const useItemByLanguage = (englishItem, spanishItem) => {
  const lang = useSelector((s) => s.language);
  return lang === "en" ? englishItem : spanishItem;
}