import { FormattedMessage } from "react-intl";
import BecomeEngineer from "../components/Landing/BecomeEngineer";
import DefiHackeos from "../components/Landing/DefiHackeos";
import NewsLanding from "../components/Landing/NewsLanding";
import { useNavigate } from "react-router-dom";
import Mentoring from "../components/Landing/Mentoring";
import NFTsLanding from "../components/Landing/NFTsLanding";
import SalaryAnimation from "../components/Landing/SalaryAnimation";
import "../styles/landing.css";


const Landing = () => {

  const navigate = useNavigate()
  return (
    <main className="main-landing">
      <BecomeEngineer />
      <NewsLanding />
      <Mentoring />
      <section className="landing-courses-section">
        <h2 className="landing-title"><FormattedMessage id="blockchain-courses" /></h2>
        <span className="text-landing-courses"><FormattedMessage id="blockchain-courses-text" /></span>
        <button className="access-courses-from-landing" onClick={() => navigate("/courses")}><FormattedMessage id="access-to-the-courses" /></button>
      </section>
      <NFTsLanding />
      <SalaryAnimation />
      <DefiHackeos />
    </main>
  );
};

export default Landing;
