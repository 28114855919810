import { FormattedMessage } from "react-intl"
import { DIFFICULTY } from "../../constants"
import { useSelector } from "react-redux"
import difficultyImg from '../../images/difficulty.svg'
import clock from '../../images/clock.svg'
import price from '../../images/price.svg'
import useChapterForCourse from "../../hooks/useChaptersForCourse"

import '../../styles/individualCourse/aboutThisCourse.css'

const AboutThisCourse = ({ course }) => {
  const difficulty = course?.difficulty
  const lang = useSelector((s) => s.language)
  const { chapters, loaded } = useChapterForCourse(course?.course_id)
  const jsonChapters = Array.isArray(chapters) ? chapters : JSON.parse(chapters);


  const duration = jsonChapters?.reduce((acc, chapter) => acc + chapter?.duration, 0)
  if (!loaded) return <></>
  return (
    <section className="about-this-container">
      <div className="article-individual">
        <img src={difficultyImg} alt="icon-difficulty" className="about-course-images" />
        <div className="text-about-this">
          <span><FormattedMessage id="difficulty" /></span>
          <strong className="course-data">{DIFFICULTY[lang][difficulty]}</strong>
        </div>
      </div>
      <div className="article-individual">
        <img src={clock} alt="icon-difficulty" className="about-course-images" />
        <div className="text-about-this">
          <span><FormattedMessage id="duration" /></span>
          <strong className="course-data">{duration} min</strong>
        </div>
      </div>

      <div className="article-individual">
        <img src={price} alt="icon-difficulty" className="about-course-images" />
        <div className="text-about-this">
          <span><FormattedMessage id="price" /></span>
          <strong className="course-data">{course.price} €</strong>
        </div>
      </div>
    </section>
  )
}

export default AboutThisCourse