import React, { useState } from "react"
import { LANDING_COURSES } from "../constants"
import { FormattedMessage } from "react-intl"
import { COURSES_ENDPOINTS } from "../api/endpoints"
import { useGetData } from "../hooks/useGetData"
import CoursesInfo from "../components/Courses/CoursesInfo"
import lupaImg from '../images/lupa.svg'
import "../styles/courses/courses.css"

const Courses = () => {
  const [filteredCourses, setFilterdCourses] = useState([])
  const [isFiltering, setIsFiltering] = useState(false)
  const localStorageCourses = window.localStorage.getItem("courses") ? JSON.parse(window.localStorage.getItem("courses")) : undefined
  const { result: courses, loaded, error } = useGetData(COURSES_ENDPOINTS.getCourses, localStorageCourses?.courses)
  console.log("courses", courses)
  if (!localStorageCourses && courses) {
    const coursesObject = {
      "courses": courses,
      "timestamp": Math.floor(new Date() / 1000)
    }
    window.localStorage.setItem("courses", JSON.stringify(coursesObject))
  }


  const filterHandler = (ev) => {
    if (ev.target.value === "") {
      setIsFiltering(false)
    }
    else {
      setIsFiltering(true)
      const value = (ev.target.value).toLowerCase()
      const filtered = courses.filter(c => (c.title_en).toLowerCase().includes(value) || (c.title_esp).toLowerCase().includes(value))
      if (filtered.length > 0) setFilterdCourses(filtered)
      else setFilterdCourses([])
    }
  }
  const courseToShow = isFiltering ? filteredCourses : courses

  const freeCourses = courseToShow && courseToShow.state !== 404 ? courseToShow?.filter(c => c?.category === LANDING_COURSES.free) : []
  const completeCourses = courseToShow && courseToShow.state !== 404? courseToShow?.filter(c => c?.category === LANDING_COURSES.complete): []
  const projectCourses = courseToShow && courseToShow.state !== 404 ?  courseToShow?.filter(c => c?.category === LANDING_COURSES.projects): []

  return (
    <main className="courses-main">
      <article className="search-containers">
        <img src={lupaImg} alt="search icon" className={isFiltering ? "search-icon opacity-2" : "search-icon"} />
        <input type="text" className="search-input" placeholder="      search favourite course" onChange={filterHandler} />
      </article>

      {projectCourses?.length > 0 && <>
      <h3 className="section-titles"><FormattedMessage id={`landing-courses-${LANDING_COURSES.projects}`} /></h3>
      {loaded && !error && (
        <div className="courses-list">
          {projectCourses?.map(c => (<CoursesInfo key={c?.title_en} course={c} />))}
        </div>
      )}
      </>}

      {freeCourses?.length > 0 && <>
      <h3 className="section-titles"><FormattedMessage id={`landing-courses-${LANDING_COURSES.free}`} /> </h3>
      {loaded && !error && (
        <div className="courses-list">
          {freeCourses?.map(c => (<CoursesInfo key={c?.title_en} course={c} />))}
        </div>
      )}
      </>}

      {completeCourses?.length > 0 && <>
      <h3 className="section-titles"><FormattedMessage id={`landing-courses-${LANDING_COURSES.complete}`} /></h3>
      {loaded && !error && (
        <div className="courses-list">
          {completeCourses?.map(c => (<CoursesInfo key={c?.title_en} course={c} />))}
        </div>
      )}
      </>}


    </main>
  )
}

export default Courses
