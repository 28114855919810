import { FormattedMessage } from 'react-intl'
import twitter from '../../images/twitter.svg'
import discord from '../../images/discord.svg'
import '../../styles/landing/newsLanding.css'

const NewsLanding = () => {
  const twitterUrl = "https://twitter.com/0xBoostHub"
  const discordUrl = "https://discord.gg/Jg3PVWj5h7"
  return (
    <>
      <div className="news-landing-marquee">
        <p className="news-landing-marquee-text"><span className="star-icon">🌟</span><FormattedMessage id="join-discord-text" /><span className="star-icon">🌟</span>  </p>
      </div>
      <div className="social-media-container">
        <div className="social-icon-container">
          <p>Twitter</p>
          <a href={twitterUrl} target='blank_' rel='noreferrer'>
            <img src={twitter} alt="twitter-logo" className="social-media-img" />
          </a>
        </div>
        <div className="social-icon-container">
          <a href={discordUrl} target='blank_' rel='noreferrer'>
            <img src={discord} alt="discord-logo" className="social-media-img" />
          </a>
          <p>Discord</p>
        </div>

      </div>
    </>
  )

}
export default NewsLanding