export const LANDING_COURSES = Object.freeze({
  free: "free",
  projects: "projects",
  complete: "complete",
})

export const MENTORY_FORM_URL = "https://forms.gle/u9T1S4dWZ4FcznnUA"
export const MOBILE_SCREEN_SIZE = 600

export const SIGN_ERROR_NAMES = Object.freeze({
  signInError: "SignInDataError",
  signUpError: "SignUpDataError",
  emailFormatError: "EmailFormatError",
  internalServerError: "IntervalServerError",
  networkError: "NetworkError",
})

export const HTTP_CODES = Object.freeze({
  ok: 200,
  unauthorized: 401,
  notfound: 404,
  conflict: 409,
  server: 500,
})
// About course information
export const DIFFICULTY = Object.freeze({
  en: {
    low: "Low",
    medium: "Medium",
    high: "High",
  },
  esp: {
    low: "Baja",
    medium: "Media",
    high: "Alta",
  },
})

// These are the options to win points
export const POINTS_OPTIONS = Object.freeze({
  form: "form",
  buyCourse: "buycourse",
  completedCourse: "completedcourse",
})

// These are the points amount for each case
export const POINTS_AMOUNT = Object.freeze({
  form: 100,
  buyCourse: 1000,
  completedCourse: 500,
})
