export const testetTokenAddress = "0x73459845a971490ba8e1f424a87aa79fad7ff910"

export const PAYMENT_CONTRACT_ADDRESS = "0x92e0010A5520FF871E8578471366691ffa0ee4fb";

// MUMBAI
export const TOKENS_FOR_PAY_ADDRESS = {
  USDC: "0x73459845a971490ba8e1f424a87aa79fad7ff910",
  USDT: "0x73459845a971490ba8e1f424a87aa79fad7ff910",
  DAI: "0x73459845a971490ba8e1f424a87aa79fad7ff910"
};

// // ARBITRUM
// export const TOKENS_FOR_PAY_ADDRESS = {
//   USDC: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
//   USDT: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
// }