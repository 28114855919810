import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import analyse from '../images/mentory/mentory-analyze.svg'
import price from '../images/mentory/mentory-price.svg'
import calendar from '../images/mentory/mentory-calendar.svg'
import contact from '../images/mentory/mentory-contact.svg'
import rightArrow from '../images/mentory/rightArrow.svg'
import { MENTORY_FORM_URL } from '../constants'
import '../styles/mentory.css'

const Mentory = () => {
  const [showMentorySteps, setShowMentorySteps] = useState(false)
  const [showExtraInfo, setShowExtraInfo] = useState(false)

  const handleShowInfo = (isMentorySteps) => {
    if (isMentorySteps) setShowMentorySteps(!showMentorySteps)
    else setShowExtraInfo(!showExtraInfo)
  }
  return (
    <section className="mentory-container">
      <h1 className="mentory-title"><FormattedMessage id="mentory-program" /></h1>
      <p className="mentory-program-text"><FormattedMessage id="mentory-spots-text" /> <b className="spots-very-limited"><FormattedMessage id="mentory-spot-limited" /></b></p>
      <a className="mentory-enroll-now" href={MENTORY_FORM_URL} target="_blank" rel='noreferRer'><FormattedMessage id="enroll-now" /></a>
      <div className="mentory-steps-container" onClick={() => handleShowInfo(true)}>
        <img src={rightArrow} alt="right-arrow" className={!showMentorySteps ? "right-arrow-img" : "right-arrow-img-90deg"} />
        <h2 className="how-mentory-program"><FormattedMessage id="how-mentory-program" /></h2>
      </div>
      <ol className={!showMentorySteps ? "mentory-steps-list hidden-list" : "mentory-steps-list show-list"}>
        <li className="mentory-ordered-list-item">Enroll to the program and fill the form to comment us your objective.</li>
        <li className="mentory-ordered-list-item">In the following 24 hours, we will analyse your information and  tell you to schedule a call to talk specifically about the mentory.</li>
        <li className="mentory-ordered-list-item">After the call, we will prepare a perfect defined roadmap to boost your knowledge to reach your objective.</li>
        <li className="mentory-ordered-list-item">Mentory start! We will keep in touch frequently to  explain you the next steps of the roadmap and helping you with all the doubts.</li>
      </ol>
      <div className="mentory-steps-container" onClick={() => handleShowInfo(false)}>
        <img src={rightArrow} alt="right-arrow" className={!showExtraInfo ? "right-arrow-img" : "right-arrow-img-90deg"} />
        <h2 className="how-mentory-program"><FormattedMessage id="extra-info-mentory" /></h2>
      </div>
      <ul className={!showExtraInfo ? "mentory-steps-list hidden-list" : "mentory-steps-list show-list"}>
        <li className="item-container-mentory-list">
          <img src={analyse} alt='analyze price logo' className="list-extra-info-img" />
          <span>Mentory price depends on your starting level, the objective and the specificity of the topics to learn.</span>
        </li>
        <li className="item-container-mentory-list">
          <img src={price} alt='mentory price logo' className="list-extra-info-img" />
          <span>Mentory price will be stablish in the first call.</span>
        </li>
        <li className="item-container-mentory-list">
          <img src={calendar} alt='variable time logo' className="list-extra-info-img" />
          <span>The mentory duration is variable depending on what you prefer. The minimum time is one month.</span>
        </li>
        <li className="item-container-mentory-list">
          <img src={contact} alt='weekly contact logo' className="list-extra-info-img" />
          <span>The mentory consist on weekly monitoring of your progress. We will keep in touch by email, telegram and a weekly call.</span>
        </li>
      </ul>
    </section>
  )
}

export default Mentory

