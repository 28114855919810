export const surveyOptionalESP = {
  surveyId: 1,
  userPoints: 100,
  optionalQuestions: ["Qué idoma prefieres?", "Qué tecnología prefieres?", "Tienes experiencia previa?"],
  options: [
    ["Inglés", "Español"],
    ["Solidity", "JavaScript"],
    ["Si", "No"],
  ],
  textQuestion: "Incluye cualquier comentario para mejorar nuestro servicio",
}

export const surveyOptionalEN = {
  surveyId: 1,
  userPoints: 100,
  optionalQuestions: ["What language do you prefer?", "What technology do you prefer", "Do you have prior experience?"],
  options: [
    ["English", "Spanish"],
    ["Solidity", "JavaScript"],
    ["Yes", "No"],
  ],
  textQuestion: "Include any comment for improving our service",
}
