import { COURSES_ENDPOINTS } from "../../api/endpoints"
import { POINTS_AMOUNT, POINTS_OPTIONS } from "../../constants"
import addUserPoints from "../../helpers/AddUserPoints"

const addBuyCourseToDB = async course => {
  const userMail = window.localStorage.getItem("email")
  await fetch(COURSES_ENDPOINTS.buyCourse, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: window.localStorage.getItem("token"),
    },
    body: JSON.stringify({
      userEmail: userMail,
      courseId: course.course_id,
    }),
  })
  await addUserPoints(POINTS_OPTIONS.buyCourse, POINTS_AMOUNT.buyCourse)
  window.localStorage.removeItem(userMail)
}

export default addBuyCourseToDB
