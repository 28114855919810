
import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import redcross from "../images/redcross.svg"
import FormVerifyCode from "../components/ResetPassword/FormVerifyCode"
import FormRequestCode from "../components/ResetPassword/FormRequestCode"
import FormNewPassword from "../components/ResetPassword/FormNewPassword";
import "../styles/surveyForm.css";
import '../styles/forgotPassword.css'



const ForgotPassword = () => {

  const [email, setEmail] = useState(undefined)
  const [isCodeVerified, setIsCodeVerified] = useState(false)
  const [error, setError] = useState(undefined)
  const [sent, setSent] = useState(false)

  return (
    <section className="forget-container">
      <h1 className="title-forgot-password"><FormattedMessage id="reset-password" /></h1>
      {!sent && <FormRequestCode email={email} setEmail={setEmail} setError={setError} setSent={setSent} sent={sent} />}

      {sent && !isCodeVerified && <FormVerifyCode email={email} setError={setError} setIsCodeVerified={setIsCodeVerified} />}

      {isCodeVerified && <FormNewPassword setError={setError} email={email} error={setError} />}

      {(error) &&
        <div className="error-msg-container">
          <img src={redcross} alt="Error" className="cross-img" />
          <span className="error-email"><FormattedMessage id="error-with-mail" /></span>
        </div>
      }

    </section>)
}

export default ForgotPassword