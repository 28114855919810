import { useState } from "react";
import { useStripe, useElements, PaymentElement, } from "@stripe/react-stripe-js"
import { FormattedMessage } from "react-intl";
import addBuyCourseToDB from "./addBuyCourseToDB";
import Loading from "../Loading"
import { Link } from "react-router-dom"
import checkImg from "../../images/check.svg"

const StripeCheckoutForm = ({ course }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [error, setError] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userMail = window.localStorage.getItem("email")
    if (!stripe || !elements) return

    try {
      setError(false);
      setIsProcessing(true);

      const { error, paymentIntent } = await stripe.confirmPayment({ elements, redirect: "if_required" })

      if (error?.type === "card_error" || error?.type === "validation_error") {
        setError(true);
      } if (paymentIntent && paymentIntent.status === 'succeeded') {
        setIsPaymentDone(true)
        await addBuyCourseToDB(course)
        window.localStorage.removeItem(userMail)
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error adding course bought by stripe", error)
      setError(true);
    }
    finally {
      setIsProcessing(false);
    }
  }

  return (
    <>
      {stripe && elements && <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <button className="pay-now-btn" ><FormattedMessage id="pay-now" /></button>
      </form>}
      {isProcessing && <Loading />}
      {isPaymentDone && !isProcessing && <Link to={"/personal-area/courses/" + course.course_id} className="payment-done width-100" >
        <img src={checkImg} alt="done-check" />
        <FormattedMessage id="payment-done" />
      </Link>}
      {error && <span className="error-stripe-message"><FormattedMessage id="error-stripe-payment" /></span>}
    </>

  );
};

export default StripeCheckoutForm;