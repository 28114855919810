import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import submitOptionalSurvey from "../../helpers/surveys/optionalSurveys/submitOptionalSurvey";
import "../../styles/surveyForm.css";

const OptionalSurveyForm = ({ optionalSurvey }) => {
  const [surveyInfo, setSurveyInfo] = useState({
    survey_id: optionalSurvey.surveyId,
    userPoints: optionalSurvey.userPoints,
    answers: Array(optionalSurvey.optionalQuestions.length).fill(undefined),
    textAnswer: "",
  });
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);

  const submitHandle = async (e) => {
    e.preventDefault();
    setError(false)
    setSent(false)
    await submitOptionalSurvey({ surveyInfo, setError, setSent });
  };

  const handleOptionChange = (questionIndex, selectedOption) => {
    const updatedAnswers = [...surveyInfo.answers];
    updatedAnswers[questionIndex] = selectedOption;

    setSurveyInfo({ ...surveyInfo, answers: updatedAnswers });
  };

  return (
    <section className="optional-survey-container">
      <form onSubmit={submitHandle} className="survey-container">
        {optionalSurvey.optionalQuestions.map((question, questionIndex) => (
          <div key={questionIndex}>
            <label className="form-survey-label">{question}</label>
            <div className="survey-options-container">
              {optionalSurvey.options[questionIndex].map((option) => (
                <label key={option}>
                  <input
                    type="radio"
                    name={`question_${questionIndex}`}
                    className="answer-radio-optional-survey"
                    value={option}
                    checked={surveyInfo.answers[questionIndex] === option}
                    onChange={() => handleOptionChange(questionIndex, option)}
                  />
                  {option}
                </label>
              ))}
            </div>
          </div>
        ))}

        <label className="form-survey-label">{optionalSurvey.textQuestion}</label>
        <textarea className="form-survey-input" maxLength={255} onChange={(ev) => setSurveyInfo({ ...surveyInfo, textAnswer: ev.target.value })} />
        <button className="form-survey-button" type="submit"><FormattedMessage id="send-survey" /></button>

        {sent && (
          <div className="error-msg-container">
            <span className="survey-sent-correct"><FormattedMessage id="success-survey" /></span>
          </div>
        )}
        {error && (
          <div className="error-msg-container">
            <span className="survey-sent-incorrect "> <FormattedMessage id="error-survey" /></span>
          </div>
        )}
      </form>
    </section>
  );
};

export default OptionalSurveyForm;
