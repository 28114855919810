export const hackedData = [
  {
    protocol: "AAVE",
    stolen: "3M $",
    vulnerability: "reentrancy-attack",
  },
  {
    protocol: "MULTICHAIN",
    stolen: "125 M $",
    vulnerability: "reentrancy-attack",
  },
  {
    protocol: "YEARN",
    stolen: "11.4 M $",
    vulnerability: "reentrancy-attack",
  },
  {
    protocol: "SushiSwap",
    stolen: "200 M $",
    vulnerability: "Approve-related bug in RouterProcessor2 contract",
  },
  {
    protocol: "Curve",
    stolen: "61 M $",
    vulnerability: "Bug related to Vyper programming language",
  },
  {
    protocol: "BNB Chain Bridge ",
    stolen: "100 M $",
    vulnerability: "Bug in cross-chain bridge",
  },
  {
    protocol: "Euler Finance",
    stolen: "200 M $",
    vulnerability: "Bug exploited using flash loans",
  },
]
