
import { FormattedMessage } from 'react-intl'
import ReactPlayer from "react-player"
import useWidthScreenSize from '../hooks/useWidthScreenSize'
import { MOBILE_SCREEN_SIZE } from '../constants'
import twitter from '../images/twitter.svg'
import discord from '../images/discord.svg'
import '../styles/videoPlayerModal.css'


const VideoPlayerModal = ({ setIsVideoOpen, videoUrl, chapterTitle }) => {
  const closeHandler = () => {
    setIsVideoOpen(false)
  }
  const screenWidth = useWidthScreenSize()
  const VIDEO_PLAYER_WIDTH = screenWidth > MOBILE_SCREEN_SIZE ? 950 : 350
  const VIDEO_PLAYER_HEIGHT = screenWidth > MOBILE_SCREEN_SIZE ? 450 : 300

  const twitterUrl = "https://twitter.com/0xBoostHub"
  const discordUrl = "https://discord.gg/Jg3PVWj5h7"
  return (
    <div className="modal-youtube">
      <div className="video-container">
        <header className='youtube-modal-header'>
          <button onClick={closeHandler} className='youtube-modal-header-button'><FormattedMessage id="x-close" /></button>
        </header>
        <h3 className="video-modal-title">{chapterTitle}</h3>
        <ReactPlayer className="videoplayer"
          url={videoUrl}
          width={VIDEO_PLAYER_WIDTH}
          height={VIDEO_PLAYER_HEIGHT}
          controls
        />
      </div>
      <div className="social-media-container">
        <a href={twitterUrl} target='blank_' rel='noreferrer'>
          <img src={twitter} alt="twitter-logo" className="social-media-img" />
        </a>
        <a href={discordUrl} target='blank_' rel='noreferrer'>
          <img src={discord} alt="discord-logo" className="social-media-img" />
        </a>
      </div>
    </div>
  )
}

export default VideoPlayerModal