import { useNavigate } from "react-router-dom"
import { POST_SUMMARY } from "../blogContent/postSummary"
import useWidthScreenSize from '../hooks/useWidthScreenSize'
import { IMAGES } from "../images/blog"
import { FormattedMessage } from "react-intl"
import '../styles/blog.css'

const BlogItem = ({ postInfo }) => {
  const navigate = useNavigate()
  const chooseImgToShow = () => {
    if (postInfo.filename === POST_SUMMARY[0].filename) return IMAGES.reentrancy
    else if (postInfo.filename === POST_SUMMARY[1].filename) return IMAGES.crosschain
    else if (postInfo.filename === POST_SUMMARY[2].filename) return IMAGES.fundingRate
    else if (postInfo.filename === POST_SUMMARY[3].filename) return IMAGES.chain
    // else if (postInfo.filename === POST_SUMMARY[4].filename) return IMAGES.fundingRate
    // else if (postInfo.filename === POST_SUMMARY[5].filename) return IMAGES.chain
  }
  const screenWidth = useWidthScreenSize()

  if (screenWidth > 600) {

    const reducedDescription = postInfo.description && postInfo.description?.slice(0, 130) + "..."
    return (
      <article className="post-card-container" onClick={() => navigate(`/blog/${postInfo?.filename}`)}>
        <img src={chooseImgToShow()} alt="icon-describing-topic" className="post-images" />
        <div className="title-description-card">
          <h4 className="post-title">{postInfo.title}</h4>
          <p className="post-description ">{reducedDescription}</p>
          <div className="date-duration-post-container">
            <span className="post-date">{postInfo.date}</span>
            <span className="post-date">{postInfo.duration} read</span>
          </div>
        </div>
      </article>
    )
  } else {
    const reducedDescription = postInfo.description && postInfo.description?.slice(0, 130) + "..."
    return (
      <article className="mobile-card-blog-container" onClick={() => navigate(`/blog/${postInfo?.filename}`)}>
        <img src={chooseImgToShow()} alt="icon-describing-topic" className="mobile-post-images" />
        <div className="title-description-card-mobile">
          <h4 className="post-title">{postInfo.title}</h4>
          <p className="post-description ">{reducedDescription}</p>
          <div className="date-duration-post-container">
            <span className="post-date">{postInfo.date}</span>
            <span className="post-date">{postInfo.duration} read</span>
          </div>
        </div>
      </article>
    )
  }

}

const Blog = () => {

  return (
    <section className="blog-section-container">
      <h2 className="blog-title"><FormattedMessage id="learning-resources" /></h2>
      <div className="extra-resources-container">
        <article className="extra-link-container">
          <a href="https://delacruz6.gumroad.com/l/aqpbm" target="blank_" rel="noreferrer" className="extra-content-link"><FormattedMessage id="web3-security-notes" /></a>
        </article>
      </div>
      <h2 className="blog-title"><FormattedMessage id="blog" /></h2>
      <div className="blog-grids">
        {POST_SUMMARY.map(post => <BlogItem key={post.filename} postInfo={post} />)}
      </div>
    </section>)
}

export default Blog