import Select from 'react-select'
import usdcImg from '../../images/usdc.png'
import usdtImg from '../../images/usdt.png'
import daiImg from '../../images/dai.png'

const TokenSelector = ({ token, setToken }) => {
  const selectOptions = [
    { value: 'USDC', label: 'USDC', image: usdcImg },
    { value: 'USDT', label: 'USDT', image: usdtImg },
    { value: 'DAI', label: 'DAI', image: daiImg },
  ];
  return (
    <Select
      value={token}
      options={selectOptions}
      onChange={(ev) => setToken(ev)}
      formatOptionLabel={t => (
        <div className="select-option">
          <img src={t.image} alt="token-logo" />
          <span>{t.label}</span>
        </div>
      )}
    />)
}

export default TokenSelector