// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-gutter: stable;
  margin: 0;
  background-image: linear-gradient(130deg, #000000, #0b080a, #140415, #0a0510, #040710);
  height: 100%;
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.global {
  margin: 0;
  /* background-image: radial-gradient(circle, #000000, #0f0109, #140415, #130920, #03102c); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-column-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
}
:root {
  --font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --nav-color: white;
  --nav-active-color: rgb(220, 114, 253);
  --color: rgb(82, 5, 27);
  --footer-height: 40px;
  --mobile-max-text-width: 350px;
  --mobile-max-container-width: 360px;
  --cta-color: #db2777;
  --second-darkest-blue-: #020991;
}
`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,wBAAwB;EACxB,SAAS;EACT,sFAAsF;EACtF,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,4FAA4F;EAC5F,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAO;EACP,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,QAAQ;AACV;AACA;EACE,2FAA2F;EAC3F,kBAAkB;EAClB,sCAAsC;EACtC,uBAAuB;EACvB,qBAAqB;EACrB,8BAA8B;EAC9B,mCAAmC;EACnC,oBAAoB;EACpB,+BAA+B;AACjC","sourcesContent":["body {\n  overflow: auto;\n  overflow-x: hidden;\n  overflow-y: auto;\n  scrollbar-gutter: stable;\n  margin: 0;\n  background-image: linear-gradient(130deg, #000000, #0b080a, #140415, #0a0510, #040710);\n  height: 100%;\n  width: 100%;\n  min-height: 100vh;\n  position: relative;\n}\n\n.global {\n  margin: 0;\n  /* background-image: radial-gradient(circle, #000000, #0f0109, #140415, #130920, #03102c); */\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex: 1;\n  height: 100%;\n  overflow: auto;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.flex-column-center {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.flex-column-start {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  gap: 5px;\n}\n:root {\n  --font-family: Roboto, Oxygen, Ubuntu, Cantarell, \"Open Sans\", \"Helvetica Neue\", sans-serif;\n  --nav-color: white;\n  --nav-active-color: rgb(220, 114, 253);\n  --color: rgb(82, 5, 27);\n  --footer-height: 40px;\n  --mobile-max-text-width: 350px;\n  --mobile-max-container-width: 360px;\n  --cta-color: #db2777;\n  --second-darkest-blue-: #020991;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
