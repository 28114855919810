import { FormattedMessage } from "react-intl"
import useWidthScreenSize from '../../hooks/useWidthScreenSize'
import { useNavigate } from "react-router-dom"
import "../../styles/personalArea/personalData.css"


// import { useGetData } from "../../hooks/useGetData"
// import { USERSPOINTS_ENDPOINTS } from "../../api/endpoints"


const PersonalData = ({ user, openSurveyModal }) => {
  // const email = window.localStorage.getItem("email")
  // const { result: points } = useGetData(USERSPOINTS_ENDPOINTS.getAllPoints + `/${email}`)
  // const totalPoints = points?.buycourse + points?.form + points?.completedcourse
  const screenWidth = useWidthScreenSize()
  const navigate = useNavigate()
  return (
    <header className="header-personal-area">
      <h4 className="personal-area-title"> <FormattedMessage id="personal-data" /></h4>
      {screenWidth > 600
        ? <section className="personal-area-header-content">
          <article className="header-left-text">
            <span className="main-text"><FormattedMessage id="email" /></span>
            <strong className="not-main-text">{user?.email}</strong>
          </article>
          <div className="header-right-text">
            {/* <article className="header-left-text">
              <span className="main-text"><FormattedMessage id="points" /></span>
              <strong className="not-main-text">{totalPoints}</strong>
            </article> */}
            <button onClick={openSurveyModal} className="open-survey-btn"> <FormattedMessage id="open-survey" /></button>
            <button onClick={()=> { navigate("/mint-nft/0")}} className="free-nft-member"> <FormattedMessage id="free-member-nft" /></button>
          </div>
        </section>
        : <section className="personal-area-header-content">
          <div className="header-right-text">
            <article className="header-left-text">
              <span className="main-text"><FormattedMessage id="email" /></span>
              <strong className="not-main-text">{user?.email}</strong>
            </article>
            {/* <article className="header-left-text">
              <span className="main-text"><FormattedMessage id="points" /></span>
              <strong className="not-main-text">{totalPoints}</strong>
            </article> */}
          </div>
          <button onClick={openSurveyModal} className="open-survey-btn"> <FormattedMessage id="open-survey" /></button>
          <button onClick={()=> { navigate("/mint-nft/0")}} className="free-nft-member"> <FormattedMessage id="free-member-nft" /></button>
        </section>
      }

    </header>
  )
}

export default PersonalData