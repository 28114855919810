import { useState, useEffect } from "react";

export const useGetData = (url, dataExist) => {
  const [newData, setNewData] = useState(dataExist)
  const [error, setError] = useState(undefined)
  const [loaded, setLoaded] = useState(undefined)
  useEffect(() => {
    if (!dataExist) {
      setLoaded(false)
      fetch(url, {
        method: "GET",
        headers: { authorization: window.localStorage.getItem("token") },
      })
        .then(res => res.json())
        .then(data => {
          if (data?.status && data?.status !== 200) setError(data)
          else setNewData(data)
        })
        .catch(e => setError(e))
        .finally(() => setLoaded(true))
    }
  }, [url, dataExist])

  if (dataExist) return { result: dataExist, error, loaded: true }
  return { result: newData, error, loaded }
}
